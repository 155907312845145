export interface AwsConfig {
  userPoolId: string;
  clientId: string;
  GRAPHQL_BASE_URL: string;
  GRAPHQL_API_KEY: string;
  region: string;
}

const configs = {
  development: {
    userPoolId: 'us-west-1_qPnLD8LBH',
    clientId: '34rm0dco94hd8nn9q6oqnfosef',
    GRAPHQL_BASE_URL: 'https://plw9trxm44.execute-api.us-east-1.amazonaws.com/graphql',
    GRAPHQL_API_KEY: 'WETrGhFivOdFLx91axtaDERWdG81Ck4GrWfauUJ1dNxE0BreAdWqqp9nJSTqmgff',
    region: 'us-west-1'
  },
  production: {
    userPoolId: 'us-west-1_D7INByLaj',
    clientId: '71t36gbegqte7vm41gb13ksd4g',
    GRAPHQL_BASE_URL: 'https://fhub5j5nv0.execute-api.us-west-1.amazonaws.com/graphql',
    GRAPHQL_API_KEY: 'WETrGhFivOdFLx91axtaDERWdG81Ck4GrWfauUJ1dNxE0BreAdWqqp9nJSTqmgff',
    region: 'us-west-1'
  },
  production2: {
    userPoolId: 'us-west-1_tStvIiZ2C',
    clientId: '7hmdm55ho83543cm7gdc2dajps',
    GRAPHQL_BASE_URL: 'https://83pd73g30k.execute-api.us-west-1.amazonaws.com/graphql',
    GRAPHQL_API_KEY: 'WETrGhFivOdFLx91axtaDERWdG81Ck4GrWfauUJ1dNxE0BreAdWqqp9nJSTqmgff',
    region: 'us-west-1'
  },
  fhf: {
    userPoolId: 'us-west-1_ED9uuxIrP',
    clientId: '10c0sq1cnvoktj944v4r8e4sh2',
    GRAPHQL_BASE_URL: 'https://ubywdd03g4.execute-api.us-west-1.amazonaws.com/graphql',
    GRAPHQL_API_KEY: 'WETrGhFivOdFLx91axtaDERWdG81Ck4GrWfauUJ1dNxE0BreAdWqqp9nJSTqmgff',
    region: 'us-west-1'
  }
} as const;

const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (hostname.endsWith('sfs.datascoop.org')) {
    return 'production';
  }
  if (hostname.endsWith('fhf.datascoop.org')) {
    return 'fhf';
  }
  if (hostname.endsWith('analyticsbot.ai')) {
    return 'production2';
  }
  return 'development';
};

export const awsConfig: AwsConfig = configs[getEnvironment()];