import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Form } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { HeatmapVisualization } from './HeatmapVisualization';
import { LogoIcon, CloseIcon } from '../../components/UI/svgIcons';
import { getHeatmapById } from './heatmapConfig';

export const HeatmapPage = () => {
  const { heatmapName } = useParams<{ heatmapName: string }>();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [heatmap, setHeatmap] = useState<any>(null);
  const [accessDenied, setAccessDenied] = useState(false);
  const [form] = Form.useForm();
  
  // Get the user's organization ID from Redux store
  const user = useSelector((store: RootState) => store.user.user);

  useEffect(() => {
    const config = getHeatmapById(heatmapName || '');
    
    if (config) {
      // Check if user has access to this heatmap
      if (config.org_id === null || config.org_id === user.org_id) {
        setHeatmap(config);
      } else {
        // User doesn't have access to this heatmap
        setAccessDenied(true);
        // Redirect to home after a short delay
        setTimeout(() => navigate('/home'), 2000);
      }
    } else {
      // Handle unknown heatmap ID
      setHeatmap({ title: 'Unknown Heatmap' });
    }
  }, [heatmapName, user.org_id, navigate]);

  const handleCancel = () => {
    setIsModalVisible(false);
    // Navigate back to home after modal closes
    setTimeout(() => navigate('/home'), 300);
  };

  if (accessDenied) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center p-8 bg-[#FEF3F2] rounded-lg border border-[#F04438]">
          <h2 className="text-[#F04438] text-xl font-semibold mb-4">Access Denied</h2>
          <p className="text-[#2C2E30]">You don't have permission to view this heatmap.</p>
          <p className="text-[#828385] mt-2">Redirecting to home page...</p>
        </div>
      </div>
    );
  }

  if (!heatmap) return null; // Loading state

  return (
    <Modal
      title={
        <div className="flex items-center px-6 py-4 border-b border-gray-200 relative">
          <div className="flex items-center">
            <LogoIcon iconClass="h-8 w-8 mr-4 text-[#445082]" />
          </div>
          <h3 className="text-xl font-bold text-[#445082] flex-grow text-center">
            {heatmap.title}
          </h3>
          <button 
            onClick={handleCancel}
            className="bg-transparent border-none text-gray-500 hover:text-gray-700 cursor-pointer absolute right-6"
          >
            <CloseIcon id="h-5 w-5" />
          </button>
        </div>
      }
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width="90%"
      style={{ top: 20 }}
      bodyStyle={{ padding: 0, height: "90vh", overflow: "auto" }}
      className="heatmap-modal"
      maskClosable={true}
      destroyOnClose={true}
      closeIcon={null}
      keyboard={false}
    >
      <HeatmapVisualization 
        heatmapId={heatmapName} 
        dataSource={heatmap.dataSource} 
        form={form}
      />
    </Modal>
  );
};