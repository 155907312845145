import { useState } from "react";
// import { HomeLearnMoreTab } from "./homeLearnMore";
import { HomeSavedTab } from "./homeSaved";
import { HomeUploadTab } from "./homeUpload";
import { HomeDatasetsTab } from "./homeDatasets";
import { DatasetIcon, DownloadIcon, PlayIcon, SavedIcon, HeatmapIcon, UploadIcon } from "../../components/UI/svgIcons";
import { HomeHeatmapTab } from "./homeHeatmap";
export enum HomeTabEnum {
  saved = "saved",
  datasets = "datasets",
  upload = "upload",
  learn = "learn",
  heatmap = "heatmap",
}

export const HomePage = () => {
  const [activeTab, setActiveTab] = useState(HomeTabEnum.saved);


  const handleTabClick = (tab: HomeTabEnum) => {
    setActiveTab(tab);
  };
  return (
      <main className="main">
        
        <section className="">
          <div className="items-center flex container-custom flex-col">
            <h1 className="accent-title-dark mb-[56px] pt-10">
              Analysis Hub
            </h1>
            <div className="items-center flex justify-between w-full bg-[#f0f2f5] gap-[21px] max-w-[864px] mb-[48px] px-[24px] py-[12px] rounded-[24px]">
            <button
              onClick={() => handleTabClick(HomeTabEnum.saved)}
              className={`items-center flex w-full duration-300 ease-in-out font-medium justify-center leading-[120%] max-w-[258px] p-[10px] rounded-[10px] text-[14px] ${activeTab === HomeTabEnum.saved ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
            >
              <SavedIcon iconClass="mr-2" />
              Analyses
            </button>
            <button
              onClick={() => handleTabClick(HomeTabEnum.heatmap)}
              className={`items-center flex w-full duration-300 ease-in-out font-medium justify-center leading-[120%] max-w-[258px] p-[10px] rounded-[10px] text-[14px] ${activeTab === HomeTabEnum.heatmap ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
            >
              <HeatmapIcon iconClass="mr-2" />
              DriverMaps
            </button>
            <button
              onClick={() => handleTabClick(HomeTabEnum.datasets)}
              className={`items-center flex w-full duration-300 ease-in-out font-medium justify-center leading-[120%] max-w-[258px] p-[10px] rounded-[10px] text-[14px] ${activeTab === HomeTabEnum.datasets ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
            >
              <DatasetIcon iconClass="mr-2" />
              Datasets
            </button>
            <button
              onClick={() => handleTabClick(HomeTabEnum.upload)}
              className={`items-center flex w-full duration-300 ease-in-out font-medium justify-center leading-[120%] max-w-[258px] p-[10px] rounded-[10px] text-[14px] ${activeTab === HomeTabEnum.upload ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
            >
              <UploadIcon iconClass="mr-2" />
              Upload CSV
            </button>
            {/* <button
              onClick={() => handleTabClick(HomeTabEnum.learn)}
              className={`items-center flex w-full duration-300 ease-in-out font-medium justify-center leading-[120%] max-w-[258px] p-[10px] rounded-[10px] text-[14px] ${activeTab === HomeTabEnum.learn ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
            >
              <PlayIcon iconClass="mr-2" />
              Learn more
            </button> */}
          </div>

            {activeTab === HomeTabEnum.saved && <HomeSavedTab />}
            {activeTab === HomeTabEnum.datasets && <HomeDatasetsTab />}
            {activeTab === HomeTabEnum.upload && <HomeUploadTab />}
            {/* {activeTab === HomeTabEnum.learn && <HomeLearnMoreTab />} */}
            {activeTab === HomeTabEnum.heatmap && <HomeHeatmapTab />}
          </div>
        </section>
      </main>
  );
};
