import { useState, useCallback } from 'react';
import { FormInstance } from 'antd/es/form/Form';

interface UseFilterFormProps {
  onSent?: () => void;
  form: FormInstance;
}

interface Filter {
  column?: string;
  operator?: string;
  columnsToCompare?: string[];
}

export const useFilterForm = ({ onSent, form }: UseFilterFormProps) => {
  const [updateCounter, setUpdateCounter] = useState(0);
  const [resetCounter, setResetCounter] = useState(0);

  const updatePayload = useCallback(async (payload: any, config: any) => {
    try {
      const isTouched = form.isFieldsTouched();
      if (!isTouched) {
        return { isError: true };
      }

      const formValues = form.getFieldsValue();
      const {
        analyzeDate,
        compareDate,
        compareMode,
        selectedReportType,
        selectedDateColumn,
        selectedMetricType,
        selectedMetricColumn,
        selectedMetricDirection,
        selectedGroupByColumns,
        filters = [],
        selectedPercentage,
        numberOfDimensions,
        ratio = {},
      } = formValues;

      const {
        numType,
        numCol,
        numFilters,
        denType,
        denCol,
        denFilters,
        metricName
      } = ratio;

      const payloadData = {
        fileId: config?.schema?.name,
        baseDateRange: {
          from: compareDate?.[0]?.toISOString?.(),
          to: compareDate?.[1]?.toISOString?.(),
        },
        comparisonDateRange: {
          from: analyzeDate?.[0]?.toISOString?.(),
          to: analyzeDate?.[1]?.toISOString?.(),
        },
        dateColumn: selectedDateColumn,
        dateColumnType: "",
        metricColumn: {
          aggregationOption: selectedMetricType,
          ...(selectedMetricType === 'ratio' ? {
            ratioMetric: {
              numerator: {
                filters: numFilters?.map((filter: Filter) => ({
                  column: filter?.column,
                  operator: filter?.operator,
                  values: filter?.columnsToCompare
                })) || [],
                aggregationMethod: numType,
                columnName: numCol,
              },
              metricName: metricName,
              denominator: {
                aggregationMethod: denType,
                columnName: denCol,
                filters: denFilters?.map((filter: Filter) => ({
                  column: filter?.column,
                  operator: filter?.operator,
                  values: filter?.columnsToCompare
                })) || [],
              }
            }
          } : {
            singularMetric: {
              columnName: selectedMetricColumn,
              aggregationMethod: selectedMetricType,
            },
          })
        },
        groupByColumns: selectedGroupByColumns,
        expectedValue: selectedPercentage ? (Number(selectedPercentage) / 100) : 0,
        maxNumDimensions: numberOfDimensions || 3,
        filters: filters?.map((filter: Filter) => ({
          column: filter?.column,
          operator: filter?.operator,
          values: filter?.columnsToCompare
        })) || [],
      };

      return payloadData;
    } catch (error) {
      console.error('Error updating payload:', error);
      return { isError: true };
    }
  }, [form]);

  return {
    updatePayload,
    updateCounter,
    resetCounter,
  };
}; 