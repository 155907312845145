// @ts-nocheck
import { AreaChart, DeltaBar } from '@tremor/react';
import { Button, Flex, Select, Space, Table, TableColumnsType, Tabs, Tooltip, Spin } from 'antd';
import { OverviewTable } from './overviewTable';
import { RootState } from './../../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import api from './../../utils/api';
import { shortenNumber, formatMetricValue, formatNumber, formatMetricDiff } from "utils/utils";
import { OverviewCards } from "./OverviewCards";

interface SegmentDetailsProps {
  slice: {
    serializedKey: string;
    baselineValue: { sliceValue: number };
    comparisonValue: { sliceValue: number };
    impact: number;
    changePercentage: number;
  };
}

function relDiff(a, b) {
  return (((a - b) / b) * 100);
}

function absDiff(a, b) {
  return (a - b);
}

// Consolidated function to get color for value compared to a reference value
const getValueColor = (sliceValue, referenceValue, isInverted) => {
  const isHigher = sliceValue > referenceValue;
  
  if (!isInverted) {
    // If Increasing metric (higher is better)
    return isHigher ? 'bg-green-200  text-green-700' : 'bg-red-200 text-red-700';
  } else {
    // If Decreasing metric (lower is better)
    return isHigher ? 'bg-red-200 text-red-700' : 'bg-green-200  text-green-700';
  }
};

const expandedRowRender = (columns) => (record) => {

  return (
    <Table
      rowKey="serializedKey"
      showHeader={false}
      columns={columns}
      pagination={false}
      rowClassName={() => "expanded-row"}
      expandable={{ expandedRowRender: expandedRowRender(columns), rowExpandable: (record) => record.subSlices?.length, indentSize: 0, }}
      dataSource={record.subSlices}
    />
  )
}


// @ts-ignore
export const SegmentDetails: React.FC<SegmentDetailsProps> = ({ slice }) => {
  const config = useSelector((store: RootState) => store.reports.report.config)
  const result = useSelector((store: RootState) => store.reports.result)
  const filters = useSelector((store: RootState) => store.reports.report.config.settings);
  const user = useSelector((store: RootState) => store.user.user);
  const orgId = user?.org_id;
  const dynamicKey = Object.keys(result)[0];
  const resultKeys = Object.keys(result);
  const [isLoading, setIsLoading] = useState(false);
  const [sliceFilters, setSliceFilters] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [relatedSegments, setRelatedSegments] = useState({});
  const [chart1key, setChart1Key] = useState(resultKeys[0])
  const [chart2key, setChart2Key] = useState(resultKeys[0])
  const [isTableExtended, setIsTableExtended] = useState(false);
  const [dateAggregation, setDateAggregation] = useState('day');

  // Fetch date aggregation setting when component mounts
  useEffect(() => {
    const fetchDateAggregation = async () => {
      try {
        // Get organization settings which includes date_aggregation
        const response = await api.get(`/api/v1/settings/org/${orgId}`);
        
        // Use the date_aggregation from org settings, or fall back to 'day'
        const aggregation = response.data?.date_aggregation || 'day';
        setDateAggregation(aggregation);
      } catch (error) {
        console.error('Failed to fetch date aggregation setting:', error);
        // Fall back to default
        setDateAggregation('day');
      }
    };

    if (orgId) {
      fetchDateAggregation();
    }
  }, [orgId]);

  // Format the period label based on the date aggregation setting
  const getPeriodLabel = (period) => {
    switch (period) {
      case 'day':
        return 'Daily';
      case 'week':
        return 'Weekly';
      case 'month':
        return 'Monthly';
      case 'quarter':
        return 'Quarterly';
      case 'year':
        return 'Yearly';
      default:
        return 'Daily';
    }
  };

  const splitKey = 'table_split';

  const sharedOnCell = (isMain) => (record, index) => {
    if ((record.serializedKey === splitKey) && data.length > 10) {
      if (isMain) {
        return { colSpan: 12 };
      }
      return { colSpan: 0, hidden: true };
    }

    return {};
  };

  const fetchSegmentData = async () => {
    if (!slice?.key) return;
    const keys = slice?.key;
    const mappedKeys = keys.map(key => ({
      dimension: key.dimension,
      value: key.value !== 'None' ? key.value : null
    }));
    const {
      baseDateRange,
      comparisonDateRange,
      dateColumn,
      fileId,
      filters,
      metricColumn,
      groupByColumns
    } = config.rawPayload

    setIsLoading(true)
    try {
      const response = await api.post('/api/v1/insight/file/segment', {
        baseDateRange,
        comparisonDateRange,
        dateColumn,
        fileId,
        filters,
        metricColumn,
        groupByColumns,
        segmentKey: mappedKeys,
      });
      setSegmentData(response.data)
      console.log(response.data)


      const response2 = await api.post('/api/v1/insight/file/related-segments', {
        baseDateRange,
        comparisonDateRange,
        dateColumn,
        fileId,
        filters,
        metricColumn,
        segmentKey: mappedKeys,
      });
      setRelatedSegments(response2.data)
      console.log(response2.data)
      setChart1Key(Object.keys(response2.data)[0])
      setChart2Key(Object.keys(response2.data)[0])
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchSegmentData();
    setIsTableExtended(false);
  }, [slice?.serializedKey])

  const sliceKeysVar = Object.keys(result[dynamicKey].dimensionSliceInfo).map(item => item.split('|'))

  const fillSliceWithChildren = (slice, dimensionSliceInfo, sliceKeys = sliceKeysVar) => {
    const body = { ...slice };
    const nestingLevel = body.key.length;

    body.subSlicesKeys = sliceKeys.filter((keyArray) => {
      if (keyArray.length - nestingLevel === 1) {
        return keyArray.every((key, i) => {
          if (i < nestingLevel) {
            return key === `${body.key[i].dimension}:${body.key[i].value}`;
          }
          return true;
        });
      }
      return false;
    });

    if (body.subSlicesKeys.length) {
      body.subSlices = body.subSlicesKeys.map(subSliceKeyArray => {
        const subSlice = dimensionSliceInfo[subSliceKeyArray.join('|')];
        return subSlice ? fillSliceWithChildren(subSlice, dimensionSliceInfo, sliceKeys) : null;
      }).filter(Boolean);
    }

    return body;
  };

  const relatedSegmentsArr = relatedSegments[chart2key] || [];
  const data = relatedSegmentsArr.sort((slice1, slice2) => {
    const score1 = Number(slice1.impact);
    const score2 = Number(slice2.impact);
    if (score1 < score2) return 1;
    if (score1 > score2) return -1;
    return 0;
  }).filter((slice) => {
    if (!sliceFilters.length) return true
    let isValid = true;
    sliceFilters.forEach((filter) => {
      if (!slice.key.find(key => key.dimension === filter.dimension && key.value === filter.value)) isValid = false
    })
    return isValid;
  }).map(slice => fillSliceWithChildren(slice, result[dynamicKey].dimensionSliceInfo))

  const parseValue = (value: string) => {
    const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const splitRender = (
    <Flex style={{ padding: 8 }} align="center" justify="center" gap={8}>
      {isTableExtended ? 0 : data.length - 10} of {data.length} segments skipped,
      <Button type="dashed" onClick={() => setIsTableExtended((old) => !old)}>
        {isTableExtended ? "Skip middle" : "Show All"}
      </Button>
    </Flex>
  )

  const formattedData = data.length > 10 ? (isTableExtended ? [
    ...data.slice(0, 5),
    { ...data[4], serializedKey: splitKey },
    ...data.slice(5)
  ]
    : [
      ...data.slice(0, 5),
      { ...data[4], serializedKey: splitKey },
      ...data.slice(-5)
    ]) : data;

    const maxImpact = formattedData?.[0]?.impact
    const minImpact = formattedData?.[formattedData?.length - 1]?.impact
    const singlePercentValue = Math.max(Math.abs(maxImpact), Math.abs(minImpact)) / 100
    const aggregator = result[chart2key]?.aggregationMethod;
    const isInverted = filters?.selectedMetricDirection === "decreasing";
    const isRatioMetric = aggregator?.toLowerCase() === 'ratio';
    const overallBaselineValue = result[chart2key]?.baselineValue;
    const overallComparisonValue = result[chart2key]?.comparisonValue;

  const columns: TableColumnsType = [
    {
      title: 'Segment', dataIndex: 'key.dimension', key: 'key.dimension',
      render: (value, record) => record.serializedKey === splitKey ? splitRender : (
        <div className="flex gap-3 items-center flex-wrap">
          {record.key.map((item, i) => <>
            {i > 0 && <span className="font-medium">AND</span>}
            <Tooltip title={`${item.dimension}=${item.value}`} placement="top">
              <span className="bg-indigo-200 py-1 px-2 rounded-lg segmentName">{item.dimension}={item.value}</span>
            </Tooltip>
          </>)}
        </div>
      ),
      onCell: sharedOnCell(true),
      sortDirections: ['descend', 'ascend'],
      width: "40%",
      className: 'segment-name-row'
    },
    {
      title: (
        <div className="flex items-center">
          Metric Value
          <div className="ml-1 cursor-help relative">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 10.8V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 5.2H8.008" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <Tooltip title="Analysis vs Baseline" placement="top">
              <div className="absolute inset-0"></div>
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: 'baselineValue',
      key: 'baselineValue',
      onCell: sharedOnCell(),
      render: (value, record) => {
        // Only get colors if aggregator is ratio
        const baselineValueColor = isRatioMetric ? getValueColor(record.baselineValue.sliceValue, overallBaselineValue, isInverted) : '';
        const comparisonValueColor = isRatioMetric ? getValueColor(record.comparisonValue.sliceValue, overallComparisonValue, isInverted) : '';
        
        return (
          <>
            <span className={`py-1 px-2 rounded-lg ${comparisonValueColor}`}>
              {formatMetricValue(record.comparisonValue.sliceValue, aggregator)}
            </span>
            {' vs '}
            <span className={`py-1 px-2 rounded-lg ${baselineValueColor}`}>
              {formatMetricValue(record.baselineValue.sliceValue, aggregator)}
            </span>
            <div className="bg-[#e6e6e6] inline py-1 px-2 ml-3 rounded-lg">
              {record.baselineValue.sliceValue ? Number(relDiff(record.comparisonValue.sliceValue, record.baselineValue.sliceValue)).toLocaleString('en-US', { maximumFractionDigits: 2 }) : 'N/A'}%
            </div>
          </>
        );
      },
      sortDirections: ['descend', 'ascend'],
      width: '20%',
      defaultSortOrder: 'descend',
      onCell: sharedOnCell(),
      // sorter: (a, b) => parseValue(a.value) - parseValue(b.value),
    },
    {
      dataIndex: 'impact',
      key: 'impact',
      onCell: sharedOnCell(),
      render: (value, record) => {
        const difference = value // absDiff(record.comparisonValue.sliceValue, record.baselineValue.sliceValue);
        const displayValue = Number(difference) / Number(singlePercentValue)
        return (
          <Tooltip title={`Impact: ${formatMetricDiff(difference, aggregator)}`}>
            <div> {/* Wrapper div is necessary because Tooltip needs a single child */}
              <DeltaBar
            
                value={displayValue}
                isIncreasePositive={false}
              />
            </div>
          </Tooltip>
        );
      },
      width: '40%',
    },
  ];


  // const {
  //   baselineValue,
  //   comparisonValue,
  //   impact,
  //   changePercentage,
  // } = slice;


  // const difference = impact.toLocaleString('en-US');
  // const percentageDifference = changePercentage.toFixed(2);
  // const baselineValueStr = baselineValue.sliceValue.toLocaleString('en-US');
  // const comparisonValueStr = comparisonValue.sliceValue.toLocaleString('en-US');
  // const baseRows = baselineValue.sliceValue.toLocaleString('en-US');
  // const comparisonRows = comparisonValue.sliceValue.toLocaleString('en-US');
  // const basePeriod = 'Base Period'; // Example static value
  // const comparisonPeriod = 'Comparison Period'; // Example static value

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const formatDateRange = (dateRange: string[]) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
    const startDate = new Date(dateRange[0]).toLocaleDateString('en-US', options);
    const endDate = new Date(dateRange[1]).toLocaleDateString('en-US', options);
    return `${startDate} to ${endDate}`;
  };

  const comparisonDateRange = result[dynamicKey].comparisonDateRange;
  const comparisonPeriod = formatDateRange(comparisonDateRange);

  const baseDateRange = result[dynamicKey].baselineDateRange;
  const basePeriod = formatDateRange(baseDateRange);


  const transformData = (baseline: DataByDate[], comparison: DataByDate[]) => {
    return baseline.map((baselineEntry, index) => {
      const comparisonEntry = comparison[index];
      const combinedDate = `${comparisonEntry ? formatDate(comparisonEntry.date) : ''} / ${formatDate(baselineEntry.date)}`;
      return {
        date: combinedDate,
        Baseline: baselineEntry.value,
        Analysis: comparisonEntry ? comparisonEntry.value : 0
      };
    });
  };

  console.log(chart1key, segmentData.find(item => item.name === chart1key))

  const dayByDayValueData = segmentData.find(item => item.name === chart1key) ? transformData(
    segmentData.find(item => item.name === chart1key)?.baselineValueByDate,
    segmentData.find(item => item.name === chart1key)?.comparisonValueByDate
  ) : [];
  console.log(dayByDayValueData)


  const dataFormatter = (value: number) => {
    return formatMetricValue(value, result[chart1key]?.aggregationMethod)
  };

  if (!slice) return null;

  const mainSegmentInAllMetrics = {};

  resultKeys.forEach(key => {
    mainSegmentInAllMetrics[key] = result?.[key]?.dimensionSliceInfo?.[slice?.serializedKey]
  })

  const baselineValue = mainSegmentInAllMetrics[dynamicKey]?.baselineValue?.sliceValue || 0;
  const comparisonValue = mainSegmentInAllMetrics[dynamicKey]?.comparisonValue?.sliceValue || 0;
  const baselineNumRows = mainSegmentInAllMetrics[dynamicKey]?.baselineValue?.sliceCount || 0;
  const comparisonNumRows = mainSegmentInAllMetrics[dynamicKey]?.comparisonValue?.sliceCount || 0;

  const difference = comparisonValue - baselineValue;
  const percentageDifference = baselineValue !== 0 ? (((comparisonValue - baselineValue) / baselineValue) * 100).toFixed(2) : 0;

  const differenceRows = comparisonNumRows - baselineNumRows;
  const percentageDifferenceRows = baselineNumRows !== 0 ? (((comparisonNumRows - baselineNumRows) / baselineNumRows) * 100).toFixed(2) : 0;

  return (
    <main className="main">
      <section className="">
        <div className="min-h-[80vh] bg-[#ffffff]">
          <div className="container-custom">
          <OverviewCards />
            {/* <!--Tabs content--> */}
            <div className="">
              {/* <!-- Table --> */}
              <OverviewTable
                differenceRows={differenceRows}
                percentageDifferenceRows={percentageDifferenceRows}
                difference={difference}
                percentageDifference={percentageDifference}
                baselineValue={baselineValue?.toLocaleString('en-US')}
                comparisonValue={comparisonValue?.toLocaleString('en-US')}
                aggregationMethods={Object.keys(mainSegmentInAllMetrics)}
                basePeriod={basePeriod}
                comparisonPeriod={comparisonPeriod}
                baseRows={baselineNumRows?.toLocaleString('en-US')}
                comparisonRows={comparisonNumRows?.toLocaleString('en-US')}
                // @ts-ignore
                comparisonAggregationValues={resultKeys.map(key => {
                  const baselineValueTemp = mainSegmentInAllMetrics[key]?.baselineValue?.sliceValue || 0;
                  const comparisonValueTemp = mainSegmentInAllMetrics[key]?.comparisonValue?.sliceValue || 0;
                  const aggregationMethodTemp = result[key].aggregationMethod;
                  return ({
                    value: formatMetricValue(comparisonValueTemp, aggregationMethodTemp),
                    difference: formatMetricDiff((comparisonValueTemp - baselineValueTemp), aggregationMethodTemp),
                    percentageDifference: baselineValueTemp !== 0 ? (((comparisonValueTemp - baselineValueTemp) / baselineValueTemp) * 100).toFixed(2) : 0,
                  })
                })}
                baseAggregationValues={resultKeys.map(key => {
                  const baselineValueTemp = mainSegmentInAllMetrics[key]?.baselineValue?.sliceValue || 0;
                  const comparisonValueTemp = mainSegmentInAllMetrics[key]?.comparisonValue?.sliceValue || 0;
                  const aggregationMethodTemp = result[key].aggregationMethod;
                  return ({
                    value: formatMetricValue(baselineValueTemp,aggregationMethodTemp),
                    difference: formatMetricDiff((comparisonValueTemp - baselineValueTemp), aggregationMethodTemp),
                    percentageDifference: baselineValueTemp !== 0 ? (((comparisonValueTemp - baselineValueTemp) / baselineValueTemp) * 100).toFixed(2) : 0,
                  })
                })}
              />

              {/* <!-- Chart --> */}
              <div className="p-6 border border-[#E6E6E7] mb-10 rounded-[28px] shadow-md w-full">
                <div className="mb-6">
                  <h3 className="font-semibold text-[#1D2E54] leading-[130%] text-[20px] mb-6">{getPeriodLabel(dateAggregation)} Value Trends</h3>
                  <div className="font-semibold text-[14px] text-[#7F8ACA]">
                    <Tabs defaultActiveKey="1" items={
                      resultKeys.map(key => ({
                        key,
                        label: result[key].name,
                      }))
                    }
                      onChange={(key) => setChart1Key(key)}
                      className="custom-tabs" />
                  </div>
                </div>
                <Spin spinning={isLoading}>
                  <AreaChart
                    className="max-h-[240px]"
                    data={dayByDayValueData}
                    index="date"
                    categories={['Analysis', 'Baseline']}
                    colors={["#2b3c6b","#7F8ACA"]}
                    valueFormatter={dataFormatter}
                    yAxisWidth={78}
                    onValueChange={(v) => console.log(v)}
                  />
                </Spin>

              </div>
              {/* <!-- Info --> */}
              <div className="border-[#E6E6E7] border rounded-[28px] shadow-md w-full mb-[32px]">

                <div className="p-6">
                  <div className="flex items-center justify-between gap-4 pb-6">
                    <div className="">
                      <div className="font-semibold text-[#1D2E54] leading-[130%] text-[20px] mb-2">Other Segments under the
                        Same
                        Dimension(s)
                      </div>
                      <div className="flex items-center gap-1">
                        {(slice?.key || []).map((item) => {
                          return (
                            <span key={`${item.dimension}=${item.value}`} className="font-medium px-2 py-[2px] bg-[#F3F2FF] inline-block rounded-2xl text-[#2B3C6B] text-[12px] mr-[2px]">
                              {`${item.dimension}=${item.value}`}
                            </span>
                          )
                        })}
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <Select mode="multiple" style={{ width: 200 }} placeholder="Filter" maxTagCount="responsive" onChange={(values) => {
                        setSliceFilters(values.map(value => ({
                          dimension: value.split('=')[0],
                          value: value.split('=')[1],
                        })))
                      }}>
                        {(slice?.key || []).map((item) => {
                          return (
                            <Select.Option value={`${item.dimension}=${item.value}`} key={`${item.dimension}=${item.value}`}>{`${item.dimension}=${item.value}`}</Select.Option>
                          )
                        })}
                      </Select>
                    </div>
                  </div>
                  <div className="font-medium text-[16px] leading-[150%] border-t border-t-[#7F8ACA] pt-6 text-[#7F8ACA]">
                    Metrics: <Tabs defaultActiveKey="1" items={
                      resultKeys.map(key => ({
                        key,
                        label: result[key].name,
                      }))
                    }
                      onChange={(key) => setChart2Key(key)}
                      className="custom-tabs" />

                    {/* {segmentData[0]?.name} */}
                  </div>
                </div>
                <Spin spinning={isLoading}>
                  <Table columns={columns} dataSource={formattedData} pagination={false} showSorterTooltip={{ target: 'sorter-icon' }}
                    // onChange={(pagination, filters, sorter, extra) => {
                    //   extra.currentDataSource[4].split = true
                    // }}
                    rowKey={'serializedKey'}
                    rowClassName={() => "root-row"}
                    // expandedRowKeys={data.map(slice => slice.serializedKey)}
                    expandable={{ expandedRowRender: expandedRowRender(columns), rowExpandable: (record) => record.subSlices?.length, indentSize: 0, }} />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}