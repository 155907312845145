// @ts-nocheck
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { PrivateRoute } from "./utils/router/privateRoute";
import { LoginPage } from "./modules/auth/login";
import { RegisterPage } from "./modules/auth/register";
import { ResetPasswordPage } from "./modules/auth/reset-password";
import { HomePage } from "./modules/home";
import { WelcomePage } from "./modules/welcomePage";
import { AuthProvider } from "./utils/authContext";
import { OnboardingPage } from "./modules/auth/onboarding-page";
import { ReportSetupPage } from "./modules/report-setup";
import { DiscoverSegments } from "./modules/discover-segments";
import { Spin, notification } from "antd";
import { createContext, useState } from "react";
import { AccountSettings } from "./modules/account-settings";
import { ContactUs } from "./modules/contact-us";
import { usePDF } from "react-to-pdf";
import { Terms } from "./modules/terms";
import { PrivacyPolicy } from "./modules/privacy";
import { BookingEmbed } from "./modules/book"
import { HeatmapPage } from "./modules/heatmap/HeatmapPage";
import { SettingsPage } from "./modules/settings";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const NotificationContext = createContext({ openNotification: (type: NotificationType, text?: string, title?: string) => { } });


const pdfOptions = {
  filename: 'datascoop.pdf',
  overrides: {
    canvas: {
      foreignObjectRendering: true,
      width: Math.min(window.innerWidth, 1450),

    }
  }
}

// function disableScrolling() {
//   const x = window.scrollX;
//   const y = window.scrollY;
//   window.onscroll = function () { window.scrollTo(x, y); };
// }

// function enableScrolling() {
//   window.onscroll = function () { };
// }

function noScroll() {
  window.scrollTo({
    behavior: 'instant',
    top: 0,
    left: 0,
  })
}

const disableScrolling = () =>  window.addEventListener('scroll', noScroll)
const enableScrolling = () =>  window.removeEventListener('scroll', noScroll)

function App() {
  const [notificationApi, contextHolder] = notification.useNotification();

  const { toPDF, targetRef } = usePDF(pdfOptions);
  const [isLoading, setIsLoading] = useState(false)

  const handleToPdf = (...args) => {
    setIsLoading(false);
    toPDF(...args);
    setIsLoading(false);
    enableScrolling()
  }

  const openNotification = (type: NotificationType, text?: string, titile?: string, placement?: string) => {
    notificationApi[type]({
      message: titile || String(type).toUpperCase(),
      description:
        text || 'Error',
      placement: placement || 'topRight',
    });
  };

  return (
    <Spin
      indicator={<Spin size="large" spinning={true} />}
      spinning={isLoading} >
      <div className="App" ref={targetRef} 
      //style={isLoading ? { overflowY: 'visible', maxHeight: '100vh' } :  { overflowY: 'auto', maxHeight: '100vh' } }
          //style={isLoading ? { overflowY: 'visible', maxHeight: '100vh' } :  { overflowY: 'auto', maxHeight: '100vh' } }
    >

        <AuthProvider>
          <NotificationContext.Provider value={{
            openNotification,
            notificationApi,
          }}>
            {contextHolder}
            <Routes>
              <Route path="/" element={<WelcomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/contact-us" element={<ContactUs isAuthenticated={false} />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/account-settings" element={<AccountSettings />} />
                {/* <Route path="/contact-us" element={<ContactUs />} /> */}
                <Route path="/contact-us" element={<ContactUs isAuthenticated={true} />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/report-setup" element={<ReportSetupPage />} />
                <Route path="/discover-segments" element={<DiscoverSegments toPDF={handleToPdf} setIsLoadingPdf={(v) => {
                  setIsLoading(v)
                  disableScrolling()
                }} />} />
                <Route path="/drivermap/:heatmapName" element={<HeatmapPage />} />
                <Route path="/settings" element={<SettingsPage />} />
              </Route>

              <Route path="/onboarding-page" element={<OnboardingPage />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/book" element={<BookingEmbed />} />
            </Routes>
          </NotificationContext.Provider>
        </AuthProvider>
      </div >
    </Spin>
  );
}

export default App;
