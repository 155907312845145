export interface HeatmapConfig {
  id: string;          // URL-friendly ID (e.g., "static_loss_rate")
  title: string;       // Display title (e.g., "Static Loss Rate")
  description?: string; // Optional description
  dataSource?: string; // Path to CSV file or API endpoint
  icon?: string;       // Optional icon name
  org_id: number | null; // null means visible to all organizations
  reportId: string;    // Default report ID
}

// List of available heatmaps
export const heatmaps: HeatmapConfig[] = [
  {
    id: "static_loss_rate",
    title: "Static Loss Rate", 
    description: "Visualize static loss rates across different vintages",
    // dataSource: "./data_month.csv",
    org_id: null, // Visible to all organizations
    reportId: "50" // Default report ID
  },
  // Add more heatmaps here as needed
  {
    id: "dynamic_conversion",
    title: "Dynamic Conversion",
    description: "Analyze conversion rates over time", 
    // dataSource: "./conversion_data.csv",
    org_id: 19, // Only visible to organization with ID 1
    reportId: "380" // Default report ID
  },
];

// Utility function to get heatmap by ID
export const getHeatmapById = (id: string): HeatmapConfig | undefined => {
  return heatmaps.find(heatmap => heatmap.id === id);
}; 