// @ts-nocheck
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Collapse, DatePicker, DatePickerProps, Form, Input, InputNumber, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { setReportConfig } from "../../store/reportsSlice";

import './reportSetupTab.css';
import { FormInstance, useForm } from "antd/es/form/Form";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import dayjs, { Dayjs } from "dayjs";
import {
  ArrowAroundIcon,
  SubmitIcon,
} from "../../components/UI/svgIcons";

interface OptionType {
  value: string;
  label: string;
}

const { RangePicker } = DatePicker;

const options1: OptionType[] = [
  { value: "report-1", label: "Date Range Comparison Analysis" },
];

const options3: OptionType[] = [
  { value: "sum", label: "Sum" },
  { value: "count", label: "Count" },
  { value: "nunique", label: "Distinct" },
  { value: "ratio", label: "Ratio" },
];

const optionsRatioType: OptionType[] = [
  { value: "sum", label: "Sum" },
  { value: "count", label: "Count" },
  { value: "nunique", label: "Distinct" },
];

const options5: OptionType[] = [
  { value: "increasing", label: "Increasing" },
  { value: "decreasing", label: "Decreasing" },
];

const optionsFilterOperator: OptionType[] = [
  { value: "eq", label: "Equal" },
  { value: "neq", label: "Not Equal" },
  { value: "empty", label: "Empty" },
  { value: "non_empty", label: "Not Empty" },
  { value: "gt", label: "Greater Than" },
  { value: "lt", label: "Less Than" },
];

const compareModeOptions: OptionType[] = [
  { value: "previous", label: "Previous period" },
  { value: "custom", label: "Custom" },
];

type filterType = {
  column?: string,
  operator?: string,
  columnsToCompare?: string[],
}

export const ReportSetupTab = ({ updatePayload, resetCounter, form, updateCounter, isFilterModal }: { updatePayload: (payload: any, config: any) => void, updateCounter: number, resetCounter: number, form: FormInstance, isFilterModal?: boolean, }) => {
  // @ts-ignore
  const config = useSelector((store: RootState) => store.reports.report.config)
  const [defaultValuesState, setDefaultValuesState] = useState();
  const [validDates, setValidDates] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(config?.settings?.predefinedDateRange || '')
  const schema = config?.schema;
  const narrative = config?.narrative;

  useEffect(() => {
    if (config?.settings?.predefinedDateRange) {
      setTimeout(() => {
        setSelectedDateRange(config?.settings?.predefinedDateRange)
      }, 1000)
    }
  }, [config?.settings?.predefinedDateRange])

  // @ts-ignore
  window.form = form;
  // @ts-ignore
  const optionsColumns = schema?.fields?.map?.((field) => ({
    name: field.name,
    label: field.name,
    value: field.name,
    numDistinctValues: field.numDistinctValues,
    minDate: field.minDate,
    maxDate: field.maxDate,
    type: field.type,
    numRowsByDate: field.numRowsByDate,
  })) || [];

  const optionsColumnsValues = {};

  schema?.fields?.forEach?.((field) => {
    optionsColumnsValues[field.name] = field.values.map(((value) => ({ value: value, label: value, }))) || [];
  });

  const isNumericColumn = (column: string) => {
    const field = optionsColumns.find(f => f.value === column);
    return field?.type === 'NUMBER' || field?.type === 'INTEGER' || field?.type === 'FLOAT';
  };

  // @ts-ignore
  const optionsColumnsDate = optionsColumns.filter((field) => ['DATE', 'DATETIME'].includes(field?.type))
  // @ts-ignore
  const optionsColumnsNoDate = optionsColumns.filter((field) => !['DATE', 'DATETIME'].includes(field?.type))

  let minDateVar = undefined;
  let maxDateVar = undefined;

  const selectedDateColumn = Form.useWatch('selectedDateColumn', form)
  const dateCol = schema?.fields?.find(item => item.name === selectedDateColumn) || {};

  useEffect(() => {
    if (dateCol?.numRowsByDate) {
      setValidDates((Object.keys(dateCol?.numRowsByDate) || []).map?.(key => dayjs.utc(key)))
    }
  }, [dateCol.name])

  if (validDates.length) {
    minDateVar = dayjs.min(validDates).utc();
    maxDateVar = dayjs.max(validDates).utc();
  }

  const defaultValues = config?.settings ? {
    selectedReportType: options1[0].value,
    ...config.settings,
    compareDate: config?.settings?.compareDate ? [dayjs.utc(config?.settings?.compareDate?.[0]), dayjs.utc(config?.settings?.compareDate?.[1])] : [],
    analyzeDate: config?.settings?.analyzeDate ? [dayjs.utc(config?.settings?.analyzeDate?.[0]), dayjs.utc(config?.settings?.analyzeDate?.[1])] : [],
  } : {};

  const analyzeDates = Form.useWatch('analyzeDate', form)
  const compareDates = Form.useWatch('compareDate', form)
  const compareModeWatched = Form.useWatch('compareMode', form)
  const selectedMetricTypeWatched = Form.useWatch('selectedMetricType', form)

  const analyzeDayDiff = analyzeDates?.[1]?.diff?.(analyzeDates[0], 'day') + 1 || 1

  const dispatch = useDispatch();

  const updateData = async (isForced) => {
    const {
      analyzeDate,
      compareDate,
      compareMode,
      selectedReportType,
      selectedDateColumn,
      selectedMetricType,
      selectedMetricColumn,
      selectedMetricDirection,
      selectedGroupByColumns,
      filters = [],
      selectedPercentage,
      numberOfDimensions,
      ratio = {},
    } = form.getFieldsValue();

    const {
      numType,
      numCol,
      numFilters,
      denType,
      denCol,
      denFilters,
      metricName
    } = ratio;

    try {
      const isTouched = form.isFieldsTouched();

      if (!isTouched && !isForced) {
        updatePayload({ isError: true });
        return;
      }
    } catch (e) {
      updatePayload({ isError: true });
      return;
    }
    // console.log('form updated updateData')
    const payloadData = {
      fileId: schema?.name,
      baseDateRange: {
        from: compareDate?.[0]?.toISOString?.(),
        to: compareDate?.[1]?.toISOString?.(),
      },
      comparisonDateRange: {
        from: analyzeDate?.[0]?.toISOString?.(),
        to: analyzeDate?.[1]?.toISOString?.(),
      },
      dateColumn: selectedDateColumn,
      dateColumnType: "",
      metricColumn: {
        aggregationOption: selectedMetricType,

        ...(selectedMetricType === 'ratio' ? {
          ratioMetric: {
            numerator: {
              filters: numFilters?.map?.(filter => ({
                column: filter?.column,
                operator: filter?.operator,
                values: filter?.columnsToCompare
              })) || [],
              aggregationMethod: numType,
              columnName: numCol,
            },
            metricName: metricName,
            denominator: {
              aggregationMethod: denType,
              columnName: denCol,
              filters: denFilters?.map?.(filter => ({
                column: filter?.column,
                operator: filter?.operator,
                values: filter?.columnsToCompare
              })) || [],
            }
          }
        } : {
          singularMetric: {
            columnName: selectedMetricColumn,
            aggregationMethod: selectedMetricType,
          },
        })
      },
      groupByColumns: selectedGroupByColumns,
      expectedValue: selectedPercentage ? (Number(selectedPercentage) / 100) : 0,
      maxNumDimensions: numberOfDimensions || 3,
      filters: filters?.map?.(filter => ({
        column: filter?.column,
        operator: filter?.operator,
        values: filter?.columnsToCompare
      })) || [],
    }
    const reportConfig = {
      settings: {
        compareDate: [compareDate?.[0]?.toJSON?.(), compareDate?.[1]?.toJSON?.()],
        analyzeDate: [analyzeDate?.[0]?.toJSON?.(), analyzeDate?.[1]?.toJSON?.()],
        compareMode,
        selectedReportType,
        selectedDateColumn,
        selectedMetricType,
        selectedMetricColumn,
        selectedMetricDirection,
        selectedGroupByColumns,
        filters,
        selectedPercentage,
        numberOfDimensions,
        ratio,
        predefinedDateRange: selectedDateRange,
      },
      schema: schema,
    }
    console.log(reportConfig)
    updatePayload(payloadData, reportConfig, isForced);
  }

  useEffect(() => {
    form.setFieldsValue(defaultValues)
    console.log(defaultValues)
    setDefaultValuesState(defaultValues)
    setTimeout(() => {
      updateData()
    }, 500)
  }, [])

  useEffect(() => {
    if (resetCounter > 0) {
      form.setFieldsValue(defaultValues)
      setTimeout(() => {
        updateData()
      }, 500)
    }
  }, [resetCounter]);

  useEffect(() => {
    if (updateCounter > 0) {
      // console.log('form updated')
      updateData(true)
    }
  }, [updateCounter]);

  useEffect(() => {
    setSelectedDateRange('')
    if (compareModeWatched === 'custom') {

    } else if (compareModeWatched === 'previous') {
      form.setFieldValue('compareDate', [
        dayjs.max(
          [
            dayjs.utc(form.getFieldValue('analyzeDate')[0]).subtract(analyzeDayDiff, 'day'),
            minDateVar
          ]
        )?.utc(),
        dayjs.max(
          [
            dayjs.utc(form.getFieldValue('analyzeDate')[0]).subtract(1, 'day'),
            minDateVar
          ]
        )?.utc(),
      ]);
      setTimeout(() => {
        updateData()
      }, 500)
    } else {
      if (!config?.settings?.compareDate?.[0] && !config?.settings?.compareDate?.[1]) {
        form.setFieldValue('compareDate', [null, null]);
      }
      if (!config?.settings?.analyzeDate?.[0] && !config?.settings?.analyzeDate?.[1]) {
        form.setFieldValue('analyzeDate', [null, null]);
      }
    }
  }, [compareModeWatched]);

  useEffect(() => {
    setSelectedDateRange('')
    if (compareModeWatched === 'custom') {

    } else if (compareModeWatched === 'previous') {
      form.setFieldValue('compareDate', [
        dayjs.max(
          [
            dayjs.utc(form.getFieldValue('analyzeDate')[0]).subtract(analyzeDayDiff, 'day'),
            minDateVar
          ]
        )?.utc(),
        dayjs.max(
          [
            dayjs.utc(form.getFieldValue('analyzeDate')[0]).subtract(1, 'day'),
            minDateVar
          ]
        )?.utc(),
      ]);
      setTimeout(() => {
        updateData()
      }, 500)
    }
  }, [analyzeDates]);

  useEffect(() => {
    setSelectedDateRange('')
    setTimeout(() => {
      updateData()
    }, 500)
  }, [compareDates]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && !validDates.find(date => current.isSame(date));
  };

  const areDatesDisabled = !selectedDateColumn;

  const countRowsSelected = (startDate, endDate) => {
    if (!startDate?.isValid() || !endDate?.isValid() || startDate > endDate) return 0;
    const startKey = startDate.format('YYYY-MM-DD');
    const endKey = endDate.add(1, 'day').format('YYYY-MM-DD');
    let counter = dayjs.utc(startDate)
    let sum = 0;
    do {
      const num = Number(dateCol.numRowsByDate[counter.format('YYYY-MM-DD')])
      sum += isNaN(num) ? 0 : num;
      counter = counter.add(1, 'day');
    } while (counter.format('YYYY-MM-DD') !== endKey)
    return sum;
  }

  const ratioFilters = (
    <>
      <Form.Item label="Metric name" name={['ratio', 'metricName']} rules={[{ required: true, message: 'Field cannot be empty' }]}>
        <Input placeholder="Enter metric name" disabled={isFilterModal} />
      </Form.Item>
      <Form.Item label="Select the numerator metric type" name={['ratio', 'numType']} rules={[{ required: true, message: 'Field cannot be empty' }]}>
        <Select placeholder="Select...">
          {optionsRatioType.map((item: OptionType) => (
            <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select the numerator metric column" name={['ratio', 'numCol']} rules={[{ required: true, message: 'Field cannot be empty' }]}>
        <Select placeholder="Search and select column" showSearch>
          {optionsColumns.map((item: OptionType) => (
            <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select numerator filters(optional)" rules={[{ required: true, message: 'Field cannot be empty' }]} style={{ width: '100%' }}>
        <Form.List
          name={['ratio', 'numFilters']}
          rules={[
            {
              validator: async (_, names) => {
                // Validation logic if needed
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  required={false}
                  key={field.key}
                  className="filters-list"
                  layout="horizontal"
                >
                  <Form.Item
                    validateTrigger={['onChange', 'onBlur']}
                    name={[index, 'column']}
                    label="Column"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input column or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Select placeholder="Search and select column" showSearch>
                      {optionsColumns.map((item: OptionType) => (
                        <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Operator"
                    name={[index, 'operator']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input operator or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Select placeholder="operator">
                      {optionsFilterOperator
                        .filter(op => 
                          !['gt', 'lt'].includes(op.value) || 
                          isNumericColumn(form.getFieldValue(`ratio`)?.numFilters?.[field.name]?.column)
                        )
                        .map((item: OptionType) => (
                          <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  {['gt', 'lt'].includes(form.getFieldValue(`ratio`)?.numFilters?.[field.name]?.operator) && (
                    <Form.Item
                      label="Value to compare" 
                      validateTrigger={['onChange', 'onBlur']}
                      name={[index, 'columnsToCompare']}
                      rules={[
                        {
                          required: true,
                          message: "Please input value to compare or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <InputNumber 
                        placeholder="Enter value" 
                        className="numeric-filter-input"
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          form.setFieldValue(
                            ['ratio', 'numFilters', field.name, 'columnsToCompare'],
                            value !== null && value !== undefined ? [value] : []
                          );
                        }}
                      />
                    </Form.Item>
                  )}
                  {['eq', 'neq'].includes(form.getFieldValue(`ratio`)?.numFilters?.[field.name]?.operator) && (
                    <Form.Item
                      label="Values to compare"
                      validateTrigger={['onChange', 'onBlur']}
                      name={[index, 'columnsToCompare']}
                      rules={[
                        {
                          required: true,
                          message: "Please input values to compare or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Select mode="multiple" placeholder="values to compare" maxTagCount="responsive">
                        {(optionsColumnsValues[form.getFieldValue(`ratio`).numFilters?.[field.name]?.column]?.sort?.((a, b) => {
                          if (a.label < b.label) {
                            return -1;
                          }
                          if (a.label > b.label) {
                            return 1;
                          }
                          return 0;
                        }) || []).map((item: OptionType) => (
                          <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item label="Select the denominator metric type" name={['ratio', 'denType']} rules={[{ required: true, message: 'Field cannot be empty' }]}>
        <Select placeholder="Select...">
          {optionsRatioType.map((item: OptionType) => (
            <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select the denominator metric column" name={['ratio', 'denCol']} rules={[{ required: true, message: 'Field cannot be empty' }]}>
        <Select placeholder="Search and select column" showSearch>
          {optionsColumns.map((item: OptionType) => (
            <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Select denominator filters(optional)" rules={[{ required: true, message: 'Field cannot be empty' }]} style={{ width: '100%' }}>
        <Form.List
          name={['ratio', 'denFilters']}
          rules={[
            {
              validator: async (_, names) => {
                // Validation logic if needed
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  required={false}
                  key={field.key}
                  className="filters-list"
                  layout="horizontal"
                >
                  <Form.Item
                    validateTrigger={['onChange', 'onBlur']}
                    name={[index, 'column']}
                    label="Column"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input column or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Select placeholder="Search and select column" showSearch>
                      {optionsColumns.map((item: OptionType) => (
                        <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Operator"
                    name={[index, 'operator']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input operator or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Select placeholder="operator">
                      {optionsFilterOperator
                        .filter(op => 
                          !['gt', 'lt'].includes(op.value) || 
                          isNumericColumn(form.getFieldValue(`ratio`)?.denFilters?.[field.name]?.column)
                        )
                        .map((item: OptionType) => (
                          <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  {['gt', 'lt'].includes(form.getFieldValue(`ratio`)?.denFilters?.[field.name]?.operator) && (
                    <Form.Item
                      label="Value to compare"
                      validateTrigger={['onChange', 'onBlur']}
                      name={[index, 'columnsToCompare']}
                      rules={[
                        {
                          required: true,
                          message: "Please input value to compare or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <InputNumber 
                        placeholder="Enter value" 
                        className="numeric-filter-input"
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          form.setFieldValue(
                            ['ratio', 'denFilters', field.name, 'columnsToCompare'],
                            value !== null && value !== undefined ? [value] : []
                          );
                        }}
                      />
                    </Form.Item>
                  )}
                  {['eq', 'neq'].includes(form.getFieldValue(`ratio`)?.denFilters?.[field.name]?.operator) && (
                    <Form.Item
                      label="Values to compare"
                      validateTrigger={['onChange', 'onBlur']}
                      name={[index, 'columnsToCompare']}
                      rules={[
                        {
                          required: true,
                          message: "Please input values to compare or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Select mode="multiple" placeholder="values to compare" maxTagCount="responsive">
                        {(optionsColumnsValues[form.getFieldValue(`ratio`).denFilters?.[field.name]?.column]?.sort?.((a, b) => {
                          if (a.label < b.label) {
                            return -1;
                          }
                          if (a.label > b.label) {
                            return 1;
                          }
                          return 0;
                        }) || []).map((item: OptionType) => (
                          <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );

  const rootFiltersDisabled = selectedMetricTypeWatched === 'ratio';

  const prefillDate = (mode) => {
    const newDates = {
      week: [dayjs(maxDateVar).utc().subtract(6, 'days'), dayjs(maxDateVar).utc()],
      month: [dayjs(maxDateVar).utc().subtract(27, 'days'), dayjs(maxDateVar).utc()],
      quarter: [dayjs(maxDateVar).utc().subtract(90, 'days'), dayjs(maxDateVar).utc()],
      year: [dayjs(maxDateVar).utc().subtract(363, 'days'), dayjs(maxDateVar).utc()],
    }

    form.setFieldValue('analyzeDate', [dayjs.max(newDates[mode][0], minDateVar), newDates[mode][1]]);
    form.setFieldValue('compareMode', 'previous');
    setTimeout(() => {
      updateData()
      setSelectedDateRange(mode)
    }, 500)
  }

  return (
    <div className="reportSetupTab w-full border-[#E6E6E7] border accent-block max-w-[1088px] px-6 py-3">
      <Form
        variant="outlined"
        form={form}
        onBlur={() => updateData()}
        onValuesChange={() => updateData()}
        onMouseLeave={() => updateData()}
        scrollToFirstError
        labelCol={{ span: 6 }}>
        <Form.Item label="Select Analysis Type" name="selectedReportType" rules={[{ required: true, message: 'Field cannot be empty' }]} tooltip="The type of analysis intended.">
          <Select placeholder="Select Analysis Type">
            {options1.map((item: OptionType) => (
              <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select Date Column" name="selectedDateColumn" rules={[{ required: true, message: 'Field cannot be empty' }]} tooltip="Choose the column that is parsable to dates." extra={<>
          <span className="font-medium">E.g. 2020-04-13.</span> See supported format{' '}
          <Link
            to="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format"
            target="_blank"
            className="text-[#7F8ACA] underline underline-offset-2"
            rel="noreferrer"
          >
            here
          </Link>
        </>}>
          <Select placeholder="Select Date Column">
            {optionsColumnsDate.map((item: OptionType) => (
              <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select Date range to Analyze" rules={[{ required: true, message: 'Field cannot be empty' }]}
          extra={analyzeDates?.[0] && analyzeDates?.[1]
            ? `${analyzeDates[1].diff(analyzeDates[0], 'day') + 1} days and ${countRowsSelected(analyzeDates?.[0], analyzeDates?.[1])} rows selected`
            : "Select a date range"}
          style={{

          }}>
          <div style={{
            display: "flex",
            height: 43.6,
          }}>
            <Form.Item name="analyzeDate"
              style={{
                width: 288,
              }}>
              <RangePicker allowClear={false} disabled={areDatesDisabled} disabledDate={disabledDate} minDate={dayjs.utc(minDateVar)} maxDate={dayjs.utc(maxDateVar)}
              // renderExtraFooter={() => (

              // )}
              />
            </Form.Item>
            <div style={{
              display: "flex",
              alignItems: 'center',
              paddingLeft: 8,
              gap: 4,
            }}>
              <Button type={selectedDateRange === 'week' ? 'primary' : 'default'} disabled={areDatesDisabled} onClick={() => prefillDate('week')}>W/W</Button>
              <Button type={selectedDateRange === 'month' ? 'primary' : 'default'} disabled={areDatesDisabled} onClick={() => prefillDate('month')}>M/M</Button>
              <Button type={selectedDateRange === 'quarter' ? 'primary' : 'default'} disabled={areDatesDisabled} onClick={() => prefillDate('quarter')}>Q/Q</Button>
              <Button type={selectedDateRange === 'year' ? 'primary' : 'default'} disabled={areDatesDisabled} onClick={() => prefillDate('year')}>Y/Y</Button>
            </div>
          </div>
        </Form.Item>

        <Form.Item label="Select Date range to Compare" rules={[{ required: true, message: '' }]} name="compareMode">
          <Form.Item rules={[{ required: true, message: 'Field cannot be empty' }]} name="compareMode" style={{ marginBottom: 0 }}>
            <Select placeholder="Mode" disabled={areDatesDisabled || !(analyzeDates[0] && analyzeDates[1])}>
              {compareModeOptions.map((item: OptionType) => (
                <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          {true && (
            <Form.Item style={{ marginBottom: 0, marginTop: 24 }} rules={[{ required: true, message: 'Field cannot be empty' }]} name="compareDate"
              extra={compareDates?.[0] && compareDates?.[1]
                ? `${compareDates[1].diff(compareDates[0], 'day') + 1} days and ${countRowsSelected(compareDates?.[0], compareDates?.[1])} rows selected`
                : "Select a date range"}>
              <RangePicker allowClear={false} disabledDate={disabledDate} disabled={areDatesDisabled || !(analyzeDates[0] && analyzeDates[1])} minDate={dayjs.utc(minDateVar)} maxDate={dayjs.utc(maxDateVar)} />
            </Form.Item>
          )}
        </Form.Item>
        {selectedMetricTypeWatched === 'ratio' ? ratioFilters : (
          <Form.Item label="Search and select the Metric column" name="selectedMetricColumn" rules={[{ required: true, message: 'Field cannot be empty' }]}>
            <Select placeholder="Select the Metric column" showSearch>
              {optionsColumns.map((item: OptionType) => (
                <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Select the Metric type" name="selectedMetricType" rules={[{ required: true, message: 'Field cannot be empty' }]}>
          <Select placeholder="Select the Metric type" disabled={isFilterModal}>
            {options3.map((item: OptionType) => (
              <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Target Metric direction" name="selectedMetricDirection" rules={[{ required: true, message: 'Field cannot be empty' }]} tooltip="Target direction of the metric movement E.g. “Increasing for reveue and “ecreasing” for canceled orders.">
          <Select placeholder="Target Metric direction">
            {options5.map((item: OptionType) => (
              <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select group by columns" name="selectedGroupByColumns" rules={[{ required: true, message: 'Field cannot be empty' }]}>
          <Select
            allowClear
            placeholder="Select group by columns"
            mode="multiple"
            maxTagCount="responsive"
          >
            {/* @ts-ignore */}
            {optionsColumnsNoDate.filter(item => item.numDistinctValues < 2000).map((item: OptionType) => (
              // @ts-ignore
              <Select.Option className="selectedGroupByColumns-option" value={item.value} key={item.value}>{item.label}<span>{item?.numDistinctValues} distinct values</span></Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Select filters(optional)" rules={[{ required: true, message: 'Field cannot be empty' }]} style={{ width: '100%' }}>
        <Form.List
          name="filters"
          rules={[
            {
              validator: async (_, names) => {
                // Validation logic if needed
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  required={false}
                  key={field.key}
                  className="filters-list"
                  layout="horizontal"
                >
                  <Form.Item
                    validateTrigger={['onChange', 'onBlur']}
                    name={[index, 'column']}
                    label="Column"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input column or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Select placeholder="Search and select column" showSearch disabled={rootFiltersDisabled}>
                      {optionsColumns.map((item: OptionType) => (
                        <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Operator"
                    name={[index, 'operator']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input operator or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Select placeholder="operator" disabled={rootFiltersDisabled}>
                      {optionsFilterOperator
                        .filter(op => 
                          !['gt', 'lt'].includes(op.value) || 
                          isNumericColumn(form.getFieldValue(`filters`)[field.name]?.column)
                        )
                        .map((item: OptionType) => (
                          <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  {['gt', 'lt'].includes(form.getFieldValue(`filters`)[field.name]?.operator) && (
                    <Form.Item
                      label="Value to compare"
                      validateTrigger={['onChange', 'onBlur']}
                      name={[index, 'columnsToCompare']}
                      rules={[
                        {
                          required: true,
                          message: "Please input value to compare or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <InputNumber 
                        placeholder="Enter value" 
                        disabled={rootFiltersDisabled}
                        className="numeric-filter-input" 
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          form.setFieldValue(
                            [`filters`, field.name, 'columnsToCompare'],
                            value !== null && value !== undefined ? [value] : []
                          );
                        }}
                      />
                    </Form.Item>
                  )}
                  {['eq', 'neq'].includes(form.getFieldValue(`filters`)[field.name]?.operator) && (
                    <Form.Item
                      label="Values to compare"
                      validateTrigger={['onChange', 'onBlur']}
                      name={[index, 'columnsToCompare']}
                      rules={[
                        {
                          required: true,
                          message: "Please input values to compare or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Select mode="multiple" placeholder="values to compare" maxTagCount="responsive" disabled={rootFiltersDisabled}>
                        {(optionsColumnsValues[form.getFieldValue(`filters`)[field.name]?.column]?.sort?.((a, b) => {
                          if (a.label < b.label) {
                            return -1;
                          }
                          if (a.label > b.label) {
                            return 1;
                          }
                          return 0;
                        }) || []).map((item: OptionType) => {
                          if (!item.value) return null;
                          return (
                            <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  )}
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  disabled={rootFiltersDisabled}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
        <Collapse accordion>
          <Collapse.Panel header="Advanced settings (optional)" key="1">
            <Form.Item label="Expected change %" name="selectedPercentage">
              <InputNumber type="number" max={100} min={0} placeholder="0%" />
            </Form.Item>
            <Form.Item label="Max number of dimensions" name="numberOfDimensions" style={{ marginBottom: 0 }}>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="Please select"
                options={[{
                  label: '1',
                  value: 1
                }, {
                  label: '2',
                  value: 2
                }, {
                  label: '3',
                  value: 3
                }, {
                  label: '4',
                  value: 4
                }, {
                  label: '5',
                  value: 5
                }]} />
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </div>
  );
};