// @ts-nocheck
import { Table, TableColumnsType, Drawer, Tabs, Button, Select } from "antd";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { RootState } from "store";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FilterIcon } from "components/UI/svgIcons";
import { ReportSearchIcon } from "components/UI/svgIcons";
import { SegmentDetails } from "./segmentDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useOnClickOutside } from "usehooks-ts";
import { shortenNumber, formatMetricValue, formatNumber, formatMetricDiff } from "utils/utils";
import { OverviewCards } from "./OverviewCards";

interface DataType {
  key: React.Key;
  name: string;
  platform: string;
  version: string;
  upgradeNum: number;
  creator: string;
  createdAt: string;
}

function relDiff(a, b) {
  return (((a - b) / b) * 100);
}

const commonTableProps = {
  pagination: false,
}

const   arrowStyles = {
  green:  { color: 'bg-green-200', arrow: <ArrowUpOutlined className="text-green-500" /> },
  red: { color: 'bg-red-200', arrow: <ArrowDownOutlined className="text-red-500" /> },
  greenInverted: { color: 'bg-green-200', arrow: <ArrowDownOutlined className="text-green-500" /> },
  redInverted: { color: 'bg-red-200', arrow: <ArrowUpOutlined className="text-red-500" /> },
}

const expandedRowRender = (columns) => (record) => {

  return (
    <Table
      rowKey="serializedKey"
      showHeader={false}
      columns={columns}
      expandable={{ expandedRowRender: expandedRowRender(columns), rowExpandable: (record) => record.subSlices?.length, indentSize: 0, }}
      dataSource={record.subSlices}
      rowClassName={() => "expanded-row"}
      {...commonTableProps}
    />
  )
}

const THRESHOLD = {
  low: 0.075,
  medium: 0.15,
  high: 0.25,
};


export const DriversTab = ({ segments, deepFilters, formControl, showDrawer, onClose, goToOverview, drawerSlice, setDrawerSlice, pageRef, isShown, hideDrawer, }: { segments?: boolean, formControl: any, goToOverview: () => void, pageRef: any }) => {
  // const [isGrouped, setIsGrouped] = useState(true);
  const [isGrouped, setIsGrouped] = useState(segments ? true : false);
  const [expandedDimensions, setExpandedDimensions] = useState({});
  const [dimensionFilter, setDimensionFilter] = useState('all');

  const [driverFilter, setDriverFilter] = useState('all')
  const [driverSensitivity, setDriverSensitivity] = useState('medium')
  const [pValueFilter, setPValueFilter] = useState('none');

  const navigate = useNavigate();
  const resultState = useSelector((store: RootState) => store.reports.result);
  const result = resultState[Object.keys(resultState)[0]]
  const overallBaselineValue = result.baselineValue;
  const overallComparisonValue = result.comparisonValue;
  const rootPercentageDifference = result.baselineValue !== 0 ? relDiff(result.comparisonValue, result.baselineValue) : 0;
  let driverKeys = useMemo(() => segments ? Object.keys(result.dimensionSliceInfo).map(item => item.split('|')) : result.topDriverSliceKeys.map(item => item.split('|')), [deepFilters, segments]);

  let maximumSegmentKeyLength = 0;

  const rootKeys = driverKeys.filter(item => {
    if (item.length > maximumSegmentKeyLength) maximumSegmentKeyLength = item.length;
    return item.length === 1;
  })
  const allTopDriverSlices = useMemo(() => result.topDriverSliceKeys.map(key => result.dimensionSliceInfo[key]), [deepFilters, segments]);

  const sliceKeysVar = useMemo(() => segments ? Object.keys(result.dimensionSliceInfo).map(item => item.split('|')) : result.topDriverSliceKeys.map(item => item.split('|')), [deepFilters, segments]);;

  const filters = useSelector((store: RootState) => store.reports.report.config.settings);
  const isInverted = filters?.selectedMetricDirection === "decreasing";
  const isRatio = filters.selectedMetricType === 'ratio';

  const fillSliceWithChildren = (slice, dimensionSliceInfo, sliceKeys = sliceKeysVar) => {
    const body = { ...slice };
    const nestingLevel = body.key.length;
    // if (body.serializedKey === 'OS:iOS') {
    //   console.log('OS:iOS', dimensionSliceInfo, sliceKeys)
    // }
    body.subSlicesKeys = sliceKeys.filter((keyArray) => {
      if (keyArray.length - nestingLevel === 1) {
        return body.serializedKey.split('|').every((parentKey, i) => {
          return keyArray.join('|').includes(parentKey);
        });
      }
      return false;
    }).slice(0, 5);

    if (body.subSlicesKeys.length) {
      body.subSlices = body.subSlicesKeys.map(subSliceKeyArray => {
        const subSlice = dimensionSliceInfo[subSliceKeyArray.join('|')];
        if (driverFilter !== 'all' && slice.changeDev < THRESHOLD[driverSensitivity]) return null
        return subSlice ? fillSliceWithChildren(subSlice, dimensionSliceInfo, sliceKeys) : null;
      }).filter(Boolean);
    }

    return body;
  };



  const uniqueNonRootDriversKeys = useMemo(() => driverKeys.filter((keyArray) => {
    // Only consider non-root keys
    if (keyArray.length <= 1) return false;

    // Check if it's an extension of a root key
    const foundRootKey = rootKeys.find(driverKey => driverKey[0] === keyArray[0]);
    // if (!foundRootKey) console.log('!foundRootKey')
    if (foundRootKey) return false;

    // For keys with more than 2 elements, check if there's a more general 2-element key
    if (keyArray.length > 2) {
      let foundDriverKey = false;
      for (let lenIndex = 0; lenIndex < keyArray.length; lenIndex++) {
        foundDriverKey = !!driverKeys.find(driverKey =>
          driverKey.length === lenIndex && driverKey.every((driverKeyItem, i) => {
            // console.log(driverKeyItem, keyArray[i])
            return driverKeyItem === keyArray[i]
          })
        );
      }
      if (foundDriverKey) return false;
    }
    // If we've passed all checks, this is a unique non-root driver key
    return true;
  }), [deepFilters, driverKeys, isGrouped, driverSensitivity, driverFilter]);

  const uniqueNonRootDriverSlices = useMemo(() => uniqueNonRootDriversKeys.map((keyArray) => (fillSliceWithChildren(result.dimensionSliceInfo[keyArray.join('|')], result.dimensionSliceInfo))) || [], [deepFilters, uniqueNonRootDriversKeys, isGrouped, driverSensitivity, driverFilter])

  // console.log('uniqueNonRootDriversKeys', uniqueNonRootDriversKeys, uniqueNonRootDriverSlices)
  console.log('Executing driversTab.tsx')

  let rootSlices = useMemo(() => {
    let tempSlices = isGrouped ? [...rootKeys.map(keyArray => {
      const body = fillSliceWithChildren({ ...result.dimensionSliceInfo[keyArray[0]] }, result.dimensionSliceInfo)
      body.importance = result.dimensions[body?.key?.[0]?.dimension]?.score?.toFixed(2) ?? 'N/A'
      return body;
    }), ...uniqueNonRootDriverSlices] : allTopDriverSlices;
    tempSlices = tempSlices.filter(slice => (slice.baselineValue.sliceValue || slice.comparisonValue.sliceValue));
    return tempSlices;
  }, [isGrouped, driverSensitivity, driverFilter, deepFilters]);

  // rootSlices.sort((slice1, slice2) => {
  //   const impact1 = Math.abs(Number(slice1.impact));
  //   const impact2 = Math.abs(Number(slice2.impact));
  //   return impact2 - impact1; // Descending order
  // });

  // if (!segments && !isGrouped) {

  // }

  // let minNoticableImpact = 0;

  // const impactSortedTopDriverSlices = allTopDriverSlices.sort((slice1, slice2) => {
  //   const score1 = Math.abs(Number(slice1.impact));
  //   const score2 = Math.abs(Number(slice2.impact));
  //   if (score1 < score2) return 1;
  //   if (score1 > score2) return -1;
  //   return 0;
  // });

  const csvHeaders = [
    { label: "Segment", key: "Segment" },
    { label: "Analysis Period Metric Value", key: "AnalysisMetricValue" },
    { label: "Baseline Period Metric Value", key: "BaselineMetricValue" },
    { label: "Percent Difference", key: "PercentDifference" },
    { label: "Absolute Contribution", key: "AbsoluteContribution" },
    { label: "Relative Performance", key: "RelativePerformance" },
    { label: "Analysis Period Segment Size", key: "AnalysisSegmentSize" },
    { label: "Baseline Period Segment Size", key: "BaselineSegmentSize" },
  ];

  const processCsvRows = (slice) => {
    return ({
      Segment: slice.serializedKey.split('|').map(item => {
        const [dimension, value] = item.split(':');
        return `${dimension}=${value}`;
      }).join(' AND '),
      AnalysisMetricValue: slice.comparisonValue.sliceValue,
      BaselineMetricValue: slice.baselineValue.sliceValue,
      PercentDifference: slice.baselineValue.sliceValue ? Number(relDiff(slice.comparisonValue.sliceValue, slice.baselineValue.sliceValue)).toFixed(2) : 'N/A',
      AbsoluteContribution: slice.impact,
      RelativePerformance: slice.baselineValue.sliceValue ? Number(relDiff(slice.comparisonValue.sliceValue, slice.baselineValue.sliceValue) - rootPercentageDifference).toFixed(2) : 'N/A',
      AnalysisSegmentSize: slice.comparisonValue.sliceSize,
      BaselineSegmentSize: slice.baselineValue.sliceSize
    })
  }

  const generateCsvData = (slicesArg) => [
    ...slicesArg.flatMap((slice) => {
      return [
        processCsvRows(slice),
        ...(slice?.subSlices ? slice?.subSlices?.flatMap?.(subSlice => [
          processCsvRows(subSlice),
          ...(subSlice?.subSlices ? subSlice?.subSlices?.flatMap?.(subSubSlice => [processCsvRows(subSubSlice)]) : [])
        ]) : [])
      ]
    })]

  // get slices split by dimensions
  let dimensionsSlices = [];
  const sortedDimensionKeys = Object.keys(result.dimensions).sort((key1, key2) => {
    const score1 = Number(result.dimensions[key1].score);
    const score2 = Number(result.dimensions[key2].score);
    if (score1 < score2) return 1;
    if (score1 > score2) return -1;
    return 0;
  })
  const filteredDimensionKeys = sortedDimensionKeys.filter(key => {
    return dimensionFilter === 'all' || key === dimensionFilter;
  })
  if (segments) {
    dimensionsSlices = filteredDimensionKeys.map(dimensionKey => rootSlices.filter(slice => slice.key[0].dimension === dimensionKey))
  }
  const filteredRootSlices = rootSlices.filter((slice) => {
    if (driverFilter === 'all') {
      // When showing all segments, only apply p-value filter if selected
      if (pValueFilter !== 'none') {
        return slice.confidence != null && slice.confidence < Number(pValueFilter) && slice.confidence > -1;
      }
      return true;
    }
    
    if (driverFilter === 'noticeable') {
      // When showing noticeable segments, apply both filters
      const meetsChangeThreshold = slice.changeDev > THRESHOLD[driverSensitivity];
      if (pValueFilter !== 'none') {
        return meetsChangeThreshold && slice.confidence < Number(pValueFilter) && slice.confidence > -1;
      }
      return meetsChangeThreshold;
    }
    
    return false;
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isShown) {
      onClose();
    }
  }, [isShown])

  useEffect(() => {
    if (hideDrawer) {
      onClose();
    }
  }, [hideDrawer])

  // useOnClickOutside(pageRef, (event) => {
  //   if (true) {
  //     const root = document.getElementById('root')
  //     // @ts-ignore
  //     const isExcluded = !root?.contains(event.target);
  //     if (!isExcluded) {
  //       onClose()
  //     }
  //   }
  // })
  // if (segments && dimensionFilter !== 'all') {
  //   if (!sortedDimensionKeys?.find?.(key => key === dimensionFilter)) {
  //     setDimensionFilter('all')
  //   }
  // }


  const getColorAndArrow = (comparisonValue, baselineValue, isInverted) => {
    const isPositive = comparisonValue > baselineValue;

    if (!isInverted) {
      // If Increasing
      return isPositive
        ? arrowStyles.green
        : arrowStyles.red;
    } else {
      // If Decreasing
      return isPositive
        ? arrowStyles.redInverted
        : arrowStyles.greenInverted
    }
  };

  // Consolidated function to get color for value compared to a reference value
  const getValueColor = (sliceValue, referenceValue, isInverted) => {
    const isHigher = sliceValue > referenceValue;
    
    if (!isInverted) {
      // If Increasing metric (higher is better)
      return isHigher ? 'bg-green-200' : 'bg-red-200';
    } else {
      // If Decreasing metric (lower is better)
      return isHigher ? 'bg-red-200' : 'bg-green-200';
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Segment', dataIndex: 'key.dimension', key: 'key.dimension', render: (value, record) => (
        <div className="flex gap-3 items-center justify-between"><div className="flex gap-3 items-center flex-wrap">
          {record.key.map((item, i) => (
            <>
            {i > 0 && <span className="font-medium">AND</span>}
            <Tooltip title={`${item.dimension}=${item.value}`} placement="top">
              <span className="bg-indigo-200 py-1 px-2 rounded-lg segmentName">{item.dimension}={item.value}</span>
            </Tooltip>
          </>))}

        </div>
          <div className="flex gap-2 flex-shrink-0">
            <Tooltip title="Filter the analysis on this segment" placement="top">
              <span
                onClick={() => {
                  formControl.pushFilters(record.key, () => goToOverview());
                }}
                className="cursor-pointer hover:bg-gray-200 p-1 rounded"
              >
                <FilterIcon iconClass="cursor-pointer" />
              </span>
            </Tooltip>

            <Tooltip title="View segment details" placement="top">
              <span
                onClick={showDrawer(record)}
                className="cursor-pointer hover:bg-gray-200 p-1 rounded"
              >
                <ReportSearchIcon />
              </span>
            </Tooltip>
          </div>
        </div>
      ),
      sortDirections: ['descend', 'ascend'],
      width: "32%",
      className: 'segment-name-row'
    },
    {
      title: (
        <div className="flex items-center">
          Metric Value
          <div className="ml-1 cursor-help relative">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 10.8V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 5.2H8.008" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <Tooltip title="Analysis vs Baseline" placement="top">
              <div className="absolute inset-0"></div>
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: 'baselineValue',
      key: 'baselineValue',
      render: (value, record) => {
        // Only get colors if isRatio is true
        const baselineValueColor = isRatio ? getValueColor(record.baselineValue.sliceValue, overallBaselineValue, isInverted) : '';
        const comparisonValueColor = isRatio ? getValueColor(record.comparisonValue.sliceValue, overallComparisonValue, isInverted) : '';
        
        return (
          <>
            <span className={`py-1 px-2 rounded-lg ${comparisonValueColor}`}>
              {formatMetricValue(record.comparisonValue.sliceValue, filters?.selectedMetricType)}
            </span>
            {' vs '}
            <span className={`py-1 px-2 rounded-lg ${baselineValueColor}`}>
              {formatMetricValue(record.baselineValue.sliceValue, filters?.selectedMetricType)}
            </span>
            <div className="bg-[#e6e6e6] inline py-1 px-2 ml-3 rounded-lg">
              {record.baselineValue.sliceValue ? Number(relDiff(record.comparisonValue.sliceValue, record.baselineValue.sliceValue)).toLocaleString('en-US', { maximumFractionDigits: 2 }) : 'N/A'}%
            </div>
          </>
        );
      },
      sorter: (a, b) => a.comparisonValue.sliceValue - b.comparisonValue.sliceValue,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: isRatio && !segments ? 'descend' : undefined,
      width: "22%"
    },
    {
      title: 'Absolute Contribution',
      dataIndex: 'impact',
      key: 'impact',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.impact - b.impact,
      render: (value, record) => {
        const { color, arrow } = getColorAndArrow(record.comparisonValue.sliceValue, record.baselineValue.sliceValue, isInverted);
        return (
          <div className={`${color} py-1 px-2 rounded-lg flex items-center inline-flex items-center gap-2`}>
            {arrow} {formatMetricDiff(Number(value), filters?.selectedMetricType)}
          </div>
        );
      },
      width: "12%"
    },
    {
      title: 'Relative Performance',
      dataIndex: 'changePercentage',
      key: 'changePercentage',
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        const relativePerformance = record.baselineValue.sliceValue
          ? Number(relDiff(record.comparisonValue.sliceValue, record.baselineValue.sliceValue) - rootPercentageDifference).toFixed(2)
          : 'N/A';
        const { color, arrow } = getColorAndArrow(relativePerformance, 0, isInverted);
        return (
          <div className={`${color} py-1 px-2 rounded-lg inline-flex items-center gap-2`}>
            {arrow} {relativePerformance === 'N/A' ? 'N/A' : `${relativePerformance}pp`}
          </div>
        );
      },
      sorter: (a, b) => a.changePercentage - b.changePercentage,
      width: "12%"
    },
    {
      title: (
        <div className="flex items-center">
          Segment Size
          <div className="ml-1 cursor-help relative">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 10.8V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 5.2H8.008" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <Tooltip title="Analysis vs Baseline" placement="top">
              <div className="absolute inset-0"></div>
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: 'comparisonValue',
      key: 'comparisonValue',
      render: (value, record) => (
        <>
          {(record.comparisonValue.sliceSize * 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}%
          {' vs '}
          {(record.baselineValue.sliceSize * 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}%
          <div className="bg-[#e6e6e6] inline py-1 px-2 ml-3 rounded-lg">
            {record.baselineValue.sliceSize ? relDiff(record.comparisonValue.sliceSize, record.baselineValue.sliceSize).toLocaleString('en-US', { maximumFractionDigits: 2 }) : 'N/A'}%
          </div>
        </>
      ),
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a.comparisonValue.sliceSize - a.baselineValue.sliceSize) - (b.comparisonValue.sliceSize - b.baselineValue.sliceSize),
      width: "22%"
    },
  ];

  return (

    <div ref={pageRef} className={`${isShown ? 'mb-[32px]' : 'hidden mb-[32px]'}`} style={drawerSlice ? {
      overflowY: 'hidden',
      maxHeight: 100,
    } : {}}>
      <OverviewCards />
      
      {segments ? (<>
        <Tabs defaultActiveKey="all" activeKey={dimensionFilter} size="large" items={
          [
            {
              key: 'all',
              label: 'All'
            },
            ...sortedDimensionKeys.map(key => ({
              key,
              label: key,
            }))]
        }
          onChange={(key) => setDimensionFilter(key)} />
        <div className="hidden data-[twe-tab-active]:block duration-150 ease-linear transition-opacity opacity-100" id="tabs-all" role="tabpanel" aria-labelledby="tabs-all-tab" data-twe-tab-active>
          {/* <!-- Table --> */}
          {dimensionsSlices.map(slices => (<React.Fragment key={slices?.[0]?.key?.[0]?.dimension}>
            <div className="flex items-center justify-between gap-4 pt-6 border-t border-t-[#E6E6E7] mb-6 px-6">
              <div className="text-[#2B3C6B] font-semibold leading-[130%] text-[20px]">Dimension: {slices?.[0]?.key?.[0]?.dimension}
                <div className="text-[#2B3C6B] leading-[100%] text-[14px]">Importance: {result.dimensions[slices?.[0]?.key?.[0]?.dimension]?.score?.toFixed(2) ?? 'N/A'}
                </div>
              </div>
              <div className="flex items-center">
                <div className="text-[#35383B] leading-[150%] text-[16px] border-r border-r-[#E6E6E7] px-4 py-2">
                  Showing {expandedDimensions[slices?.[0]?.key?.[0]?.dimension] ? slices.length : Math.min(3, slices.length)} of {slices.length} segments
                </div>
                <button onClick={() => {
                  if (expandedDimensions[slices?.[0]?.key?.[0]?.dimension]) {
                    setExpandedDimensions(old => ({ ...old, [slices?.[0]?.key?.[0]?.dimension]: false }))
                  } else {
                    setExpandedDimensions(old => ({ ...old, [slices?.[0]?.key?.[0]?.dimension]: true }))
                  }
                }} className="text-[14px] leading-[140%] font-semibold hover:text-[#1D2E54] text-[#445082] border-r border-r-[#E6E6E7] px-[50px] py-[10px]" type="button">
                  {expandedDimensions[slices?.[0]?.key?.[0]?.dimension] ? 'Collapse' : 'Show All'}
                </button>
                <button className="flex items-center text-[14px] justify-center duration-300 ease-in-out text-[#2B3C6B] bg-[#F3F2FF] font-semibold h-[40px] hover:bg-[#D5D6FD] hover:text-[#1D2E54] leading-[150%] ml-4 px-[17px] py-[10px] rounded-xl" type="button">
                  <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5" stroke="#2B3C6B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <CSVLink headers={csvHeaders} data={generateCsvData(slices)} filename={"datascoop.csv"}>Export as CSV</CSVLink>
                </button>
              </div>
            </div >
            <div className="flex mb-6 flex-col overflow-hidden">
              <div className="lg:-mx-8 overflow-x-auto sm:-mx-6">
                <div className="min-w-full inline-block lg:px-8 sm:px-6">
                  <div className="border-[#E6E6E7] border overflow-hidden rounded-[28px] shadow-md">
                    <Table
                      rowKey="serializedKey"
                      columns={columns}
                      rowClassName={() => "root-row"}
                      expandable={{ expandedRowRender: expandedRowRender(columns), rowExpandable: (record) => record.subSlices?.length, indentSize: 0, }}
                      dataSource={expandedDimensions[slices?.[0]?.key?.[0]?.dimension] ? slices : slices.slice(0, 3)}
                      {...commonTableProps}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>))}
        </div>
      </>) : (
        <div className="py-6 border border-[#E6E6E7] rounded-[28px] shadow-md w-full">
          <div className="flex items-center justify-between gap-2 mb-6 px-4">
            <div className="flex items-center gap-2 relative pr-2">
              {/* <div className="text-[#35383B] text-[16px]">Mode:</div> */}
              {/* <!-- Dropdown menu --> */}
              <Select value={driverFilter}
                size="large"
                style={{
                  minWidth: 180,
                }}
                onChange={(v) => {
                  setDriverFilter(v)
                }}
                options={[
                  {
                    label: 'All Top Segments',
                    value: 'all'
                  },
                  {
                    label: 'Noticeable Only',
                    value: 'noticeable',
                  },
                ]} />

              <div className="text-[#35383B] text-[16px] whitespace-nowrap pl-2">P-value:</div>
                        <Select
                          value={pValueFilter}
                          size="large"
                          style={{ minWidth: 110 }}
                          onChange={(v) => setPValueFilter(v)}
                          options={[
                            { label: 'None', value: 'none' },
                            { label: '0.01', value: '0.01' },
                            { label: '0.05', value: '0.05' },
                            { label: '0.10', value: '0.10' }
                          ]}
                        />
          
              {driverFilter === 'noticeable' && (
                <>
                  <div className="text-[#35383B] text-[16px] pl-2">Sensitivity:</div>
                  <Select value={driverSensitivity}
                    size="large"
                    style={{
                      minWidth: 120,
                    }}
                    onChange={(v) => {
                      setDriverSensitivity(v)
                    }}
                    options={[
                      {
                        label: 'Low',
                        value: 'low'
                      },
                      {
                        label: 'Medium',
                        value: 'medium'
                      },
                      {
                        label: 'High',
                        value: 'high'
                      },
                    ]} />
                </>
              )}
            </div>
            <div className="border-r border-r-[#E6E6E7] h-[44px] w-[1px]"></div>
            <div className="flex items-center gap-2 px-4 relative">
              <div className="text-[#35383B] text-[16px]">Group segments:</div>
              <label className="items-center cursor-pointer inline-flex">
                <input type="checkbox" value="" className="peer sr-only" checked={isGrouped} onChange={() => {
                  setIsGrouped(old => !old)
                }} autoComplete="" />
                <div className="relative after:absolute after:bg-white after:border after:border-gray-300 after:content-[''] after:h-5 after:rounded-full after:start-[2px] after:top-[2px] after:transition-all after:w-5 bg-gray-200 h-6 peer peer-checked:after:translate-x-full peer-checked:bg-[#445082] peer-focus:outline-none peer-focus:ring-4 rounded-full rtl:peer-checked:after:-translate-x-full w-11">
                </div>
              </label>
              <Tooltip title="Groups metrics by the top level dimension" placement="top">
                <svg className="absolute end-0 top-[-10px]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_497_42999)">
                    <path d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78106 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z" stroke="#AAABAC" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_497_42999">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Tooltip>
            </div>
            <div className="border-r border-r-[#E6E6E7] h-[44px] w-[1px]"></div>
            <div className="flex items-center">
              <div className="text-[#35383B] text-[16px] leading-[150%] border-r border-r-[#E6E6E7] px-4 py-2">
                Showing {expandedDimensions.allDrivers ? filteredRootSlices.length : Math.min(100, filteredRootSlices.length)} of {filteredRootSlices.length} segments
              </div>
              <button onClick={() => {
                if (expandedDimensions.allDrivers) {
                  setExpandedDimensions(old => ({ ...old, allDrivers: false }))
                } else {
                  setExpandedDimensions(old => ({ ...old, allDrivers: true }))
                }
              }} className="text-[14px] leading-[140%] font-semibold hover:text-[#1D2E54] text-[#445082] border-r border-r-[#E6E6E7] px-[50px] py-[10px]" type="button">
                {expandedDimensions.allDrivers ? 'Collapse' : 'Show All'}
              </button>
              <button className="flex items-center text-[14px] justify-center duration-300 ease-in-out text-[#2B3C6B] bg-[#F3F2FF] font-semibold h-[40px] hover:bg-[#D5D6FD] hover:text-[#1D2E54] leading-[150%] ml-4 px-[17px] py-[10px] rounded-xl" type="button">
                <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5" stroke="#2B3C6B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <CSVLink headers={csvHeaders} data={generateCsvData(filteredRootSlices)} filename={"datascoop.csv"}>Export as CSV</CSVLink>
              </button>
            </div>
          </div>
          <div className="flex flex-col mb-[32px] overflow-hidden">
            <div className="lg:-mx-8 overflow-x-auto sm:-mx-6">
              <div className="inline-block lg:px-8 min-w-full py-2 sm:px-6">
                <div className="border-[#E6E6E7] border overflow-hidden">
                  <Table
                    rowKey="serializedKey"
                    rowClassName={() => "root-row"}
                    columns={columns}
                    expandable={{ expandedRowRender: expandedRowRender(columns), rowExpandable: (record) => record.subSlices?.length, indentSize: 0, }}
                    dataSource={expandedDimensions.allDrivers ? filteredRootSlices : filteredRootSlices.slice(0, 100)}
                    {...commonTableProps}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div >)
}