export function shortenNumber(value: number) {
  const number = Math.abs(value);
  if (number > 1000000000) {
    return formatNumber(value / 1000000000) + "B";
  } else if (number > 1000000) {
    return formatNumber(value / 1000000) + "M";
  } else if (number > 99999) {
    return formatNumber(value / 1000) + "K";
  } else {
    return formatNumber(value);
  }
}

export function formatMetricValue(num: number, metricType: string): string {
  if (metricType && metricType.toLowerCase() === "ratio") {
    return `${formatNumber(num * 100)}%`;
  }
  return shortenNumber(num);
}

export function formatMetricDiff(num: number, metricType: string): string {
  if (metricType && metricType.toLowerCase() === "ratio") {
    return `${formatNumber(num * 100)}pp`;
  }
  return shortenNumber(num);
}

export function formatNumber(num: number, digits: number = 2) {
    if (Number.isInteger(num)) {
      return num.toLocaleString('en-US');
    }
  
    return num.toLocaleString('en-US', {
      style: "decimal",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  }
  