import React, { useEffect, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReportSetupTab } from '../../../modules/report-setup/reportSetupTab';
import { setReportConfig, setResult, setResultTime, setReport } from '../../../store/reportsSlice';
import { FormInstance } from 'antd/es/form/Form';
import api from '../../../utils/api';
import { RootState } from '../../../store';
import dayjs from 'dayjs';
import { getHeatmapById } from '../heatmapConfig';

interface Schema {
  name: string;
  countRows: number;
  description?: string;
  fields: any[];
  previewData: any[];
}

interface ReportSettings {
  selectedReportType?: string;
  selectedDateColumn?: string;
  selectedMetricType?: string;
  selectedMetricColumn?: string;
  selectedMetricDirection?: string;
  selectedGroupByColumns?: string[];
  filters?: any[];
  selectedPercentage?: number;
  numberOfDimensions?: number;
  ratio?: any;
  predefinedDateRange?: string;
  compareDate?: string[];
  analyzeDate?: string[];
  compareMode?: string;
}

interface ReportFormSectionProps {
  form: FormInstance;
  updatePayload: (payload: any, config: any) => Promise<any>;
  updateCounter: number;
  resetCounter: number;
  heatmapId?: string;
}

export const ReportFormSection: React.FC<ReportFormSectionProps> = ({
  form,
  updatePayload,
  updateCounter,
  resetCounter,
  heatmapId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const config = useSelector((store: RootState) => store.reports.report.config);
  const schema = config?.schema as Schema | undefined;
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        if (!heatmapId) {
          console.error('Heatmap ID is required');
          return;
        }
        const heatmap = getHeatmapById(heatmapId);
        if (!heatmap) {
          console.error('Heatmap configuration not found');
          return;
        }
        const reportId = heatmap.reportId;
        const response = await api.get(`/api/v1/reports/${reportId}`);
        const reportData = response.data;
        
        // Parse the report_config JSON string
        if (reportData.report_config) {
          const parsedConfig = JSON.parse(reportData.report_config);
          // Update the report data with parsed config
          reportData.config = parsedConfig;
        }
        
        dispatch(setReport(reportData));
      } catch (error) {
        console.error('Error fetching report:', error);
      }
    };

    fetchReport();
  }, [dispatch, heatmapId]);

  // Pre-populate form when config changes
  useEffect(() => {
    if (config?.settings) {
      const settings = config.settings as ReportSettings;
      // Extract the relevant fields from config settings
      const formValues = {
        selectedReportType: settings.selectedReportType,
        selectedDateColumn: settings.selectedDateColumn,
        selectedMetricType: settings.selectedMetricType,
        selectedMetricColumn: settings.selectedMetricColumn,
        selectedMetricDirection: settings.selectedMetricDirection,
        selectedGroupByColumns: settings.selectedGroupByColumns,
        filters: settings.filters,
        selectedPercentage: settings.selectedPercentage,
        numberOfDimensions: settings.numberOfDimensions,
        ratio: settings.ratio,
        predefinedDateRange: settings.predefinedDateRange,
        compareDate: settings.compareDate ? [
          dayjs.utc(settings.compareDate[0]),
          dayjs.utc(settings.compareDate[1])
        ] : [],
        analyzeDate: settings.analyzeDate ? [
          dayjs.utc(settings.analyzeDate[0]),
          dayjs.utc(settings.analyzeDate[1])
        ] : [],
        compareMode: settings.compareMode,
      };

      // Set form values
      form.setFieldsValue(formValues);
    }
  }, [config, form]);

  const handleSubmit = async () => {
    try {
      const isTouched = form.isFieldsTouched();
      if (!isTouched) {
        return;
      }

      setIsSubmitting(true);
      const payload = await updatePayload({}, {});
      if (payload.isError) {
        setIsSubmitting(false);
        return;
      }

      // Get form values
      const formValues = form.getFieldsValue();

      // Add fileId from schema name and include settings
      const finalPayload = {
        ...payload,
        fileId: schema?.name
      };

      // Create the report config with settings
      const reportConfig = {
        ...config,
        settings: formValues,
        rawPayload: finalPayload
      };

      // Send data to API using the api utility
      const response = await api.post('/api/v1/insight/file/metric', finalPayload);
      const data = response.data;
      
      // Update Redux store with both the result and the report config
      dispatch(setResultTime(data.resultTime));
      dispatch(setReportConfig(reportConfig));
      dispatch(setResult(data));

      // Navigate to discover-segments
      navigate('/discover-segments');
    } catch (error) {
      console.error('Error submitting form:', error);
      setIsSubmitting(false);
    }
  };

  if (!schema) {
    return null; // Don't render the form until we have the schema
  }

  return (
    <Spin spinning={isSubmitting}>
      <div className="report-form-section">
        <ReportSetupTab
          form={form}
          updatePayload={updatePayload}
          updateCounter={updateCounter}
          resetCounter={resetCounter}
        />

        <div className="submit-button-container">
          <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </Spin>
  );
}; 