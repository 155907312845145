// @ts-nocheck
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  DimensionsIcon,
  FilterIcon,
  MetricsIcon,
  TotalIcon,
} from "../../components/UI/svgIcons";
import { CardOverview } from "./cardOverview";
import { useState } from "react";

interface OverviewCardsProps {
  // Optional props if you need to customize behavior
  className?: string;
}

export const OverviewCards = ({ className = "mb-[32px]" }: OverviewCardsProps) => {
  const result = useSelector((store: RootState) => store.reports.result);
  const resultTime = useSelector((store: RootState) => store.reports.resultTime)
  const filters = useSelector((store: RootState) => store.reports.report.config.settings);
  
  const operators = {
    eq: '=',
    neq: '=/=',
    empty: 'Empty',
    non_empty: 'Not Empty',
    gt: '>',
    lt: '<'
  };
  
  const [showDimensionsModal, setShowDimensionsModal] = useState(false);
  
  const getFiltersAndDimensions = () => {
    const data = {
      filters: [],
      dimensions: []
    };
    
    try {
      if (result) {
        Object.values(result).forEach((res) => {
          let prefix = '';
          if (res.name?.includes('numerator') && res.filters?.length) {
            prefix = 'Numerator: ';
          }
          if (res.name?.includes('denominator') && res.filters?.length) {
            prefix = 'Denominator: ';
          }
          if (res.filters?.length) {
            data.filters.push(`${prefix || ''}${res.filters ? res.filters.map(filter => 
              `${filter.column} ${operators[filter.operator]} ${filter.values?.join?.(', ') || ''}`
            ).join('\n') : ''}`);
          }
          if (!prefix && res.dimensions) {
            data.dimensions.push(Object.values(res.dimensions).map(dimension => 
              `${dimension.name}`
            ).join(', '));
          }
        });
      }
    } catch (error) {
      console.error("Error processing filters and dimensions:", error);
    }
    
    return data;
  };
  
  const getMetricsCardData = () => {
    try {
      const resArray = [];
      const dynamicKey = result ? Object.keys(result)[0] : null;
      
      if (!result || !dynamicKey) return '';
      
      if (Object.keys(result).length < 2) {
        return result[dynamicKey]?.name || '';
      }
      
      if (result[dynamicKey]?.name) {
        resArray.push(`Metric: ${result[dynamicKey].name}`);
      }
      
      Object.values(result).forEach((res) => {
        if (res.name?.includes('numerator') && filters?.ratio) {
          resArray.push(`Numerator: ${filters.ratio.numType}.${filters.ratio.numCol}`);
        }
        if (res.name?.includes('denominator') && filters?.ratio) {
          resArray.push(`Denominator: ${filters.ratio.denType}.${filters.ratio.denCol}`);
        }
      });
      
      return resArray.join('\n');
    } catch (error) {
      console.error("Error generating metrics data:", error);
      return '';
    }
  };
  
  // Safely get the data for the cards
  const filtersAndDimensions = getFiltersAndDimensions();
  const metricsCardData = getMetricsCardData();
  const dynamicKey = result ? Object.keys(result)[0] : null;
  const dimensionsCount = result && dynamicKey && result[dynamicKey]?.dimensions ? 
    Object.values(result[dynamicKey].dimensions).length : 0;
  const totalSegments = result && dynamicKey && result[dynamicKey]?.totalSegments ? 
    result[dynamicKey].totalSegments.toLocaleString('en-US') : '0';
  
  return (
    <>
      <div className={`flex items-center justify-between gap-8 ${className}`}>
        <CardOverview 
          icon={<MetricsIcon iconClass="mr-2" />} 
          title="Metrics" 
          content={metricsCardData || 'No metrics data'} 
        />
        
        <CardOverview 
          icon={<DimensionsIcon iconClass="mr-2" />} 
          title="Dimensions" 
          subContent={`${dimensionsCount} in total`}
          content={
            <div className="whitespace-pre-line">
              {dimensionsCount > 0 ? (
                <>
                  {filtersAndDimensions.dimensions[0].split(', ').slice(0, 3).join(', ')}
                  {dimensionsCount > 3 && (
                    <div className="mt-2">
                      <button 
                        className="text-[#2B3C6B] text-sm font-medium hover:underline"
                        onClick={() => setShowDimensionsModal(true)}
                      >
                        + {dimensionsCount - 3} more dimensions
                      </button>
                    </div>
                  )}
                </>
              ) : 'None Selected'}
            </div>
          }
        />
        
        <CardOverview 
          icon={<FilterIcon iconClass="mr-2" />} 
          title="Filters" 
          content={filtersAndDimensions.filters.join('\n') || 'None Selected'} 
        />
        
        <CardOverview 
          icon={<TotalIcon iconClass="mr-2" />} 
          title="Total Segments" 
          content={<>
            <div className="font-medium text-[16px] leading-[150%] text-[#35383B] mb-6">
              {totalSegments}
            </div>
            <div className="font-medium text-[16px] leading-[150%] text-[#35383B]">
                {resultTime && `Analyzed in ${Number(resultTime / 1000).toFixed(2)} second(s)`}
              </div>
          </>} 
        />
      </div>
      
      {/* Dimensions Modal */}
      {showDimensionsModal && (
        <div className="fixed inset-0 pointer-events-none flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full shadow-xl pointer-events-auto relative">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">All Dimensions ({dimensionsCount})</h3>
              <button 
                onClick={() => setShowDimensionsModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <span className="text-xl">×</span>
              </button>
            </div>
            <div className="space-y-2 max-h-[60vh] overflow-y-auto">
              {filtersAndDimensions.dimensions[0]?.split(', ').map((dimension, index) => (
                <div key={index} className="py-1 border-b border-gray-100">
                  {dimension}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
