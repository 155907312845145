// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import { Table, Button, Modal, Form, Input, Select, Spin, Typography, Layout } from 'antd';
import api from '../../utils/api';
import { NotificationContext } from '../../App';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

// Enum for setting scopes
const SettingScope = {
  GLOBAL: "global",
  ORG: "organization",
  USER: "user",
  FEATURE: "feature"
};

export const SettingsPage = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null);
  const [form] = Form.useForm();
  const { openNotification } = useContext(NotificationContext);
  const user = useSelector((store: RootState) => store.user.user);
  const orgId = user?.org_id;

  // Helper function to get setting descriptions
  const getSettingDescription = (key, scope) => {
    // Known descriptions for settings
    const descriptions = {
      'date_aggregation': 'Sets the default aggregation period of the KPIs (day, week, month, quarter, year)',
      'use_macro_signals': 'Enable/disable macro signals analysis',
      'use_internal_context': 'Enable/disable internal context retrieval',
      'use_biz_context': 'Enable/disable business context fetching',
      'use_client_encryption': 'Enable/disable client side encryption and field level encryption'
    };

    // Return known description or generic one based on scope
    return descriptions[key] || 
      (scope === 'feature' ? 'Feature flag setting' : 
       scope === 'organization' ? 'Organization setting' : 
       scope === 'user' ? 'User setting' : 'Global setting');
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      // Create an array to hold all settings
      let allSettings = [];
      
      // Fetch organization settings if org_id is available
      if (orgId) {
        try {
          const orgResponse = await api.get(`/api/v1/settings/org/${orgId}`);
          
          // Map organization settings to our format
          const orgSettings = Object.entries(orgResponse.data || {}).map(([key, value]) => ({
            key,
            value: String(value), // Ensure value is a string
            description: getSettingDescription(key, 'organization'),
            scope: SettingScope.ORG,
          }));
          
          allSettings = [...allSettings, ...orgSettings];
        } catch (err) {
          console.warn('Failed to fetch organization settings:', err);
          openNotification('error', 'Error fetching organization settings');
        }
      }
      
      // Fetch feature flags
      try {
        const featureResponse = await api.get('/api/v1/settings/feature-flags');
        
        // Map feature flags to our format
        const featureSettings = Object.entries(featureResponse.data || {}).map(([key, value]) => ({
          key,
          value: String(value), // Ensure value is a string
          description: getSettingDescription(key, 'feature'),
          scope: SettingScope.FEATURE,
        }));
        
        allSettings = [...allSettings, ...featureSettings];
      } catch (err) {
        console.warn('Failed to fetch feature flags:', err);
      }
      
      // Check if date_aggregation setting is missing and add a default
      if (!allSettings.some(s => s.key === 'date_aggregation')) {
        allSettings.push({
          key: 'date_aggregation',
          value: 'day', // Default value
          description: getSettingDescription('date_aggregation', 'organization'),
          scope: SettingScope.ORG,
        });
      }
      
      setSettings(allSettings);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
      openNotification('error', 'Failed to fetch settings');
    }
  };

  const handleEdit = (record) => {
    setCurrentSetting(record);
    form.setFieldsValue({
      key: record.key,
      value: record.value,
      scope: record.scope,
      description: record.description
    });
    setEditModalVisible(true);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      
      const response = await api.put('/api/v1/settings/setting', {
        key: values.key,
        value: values.value,
        scope: values.scope,
        scope_id: currentSetting.scope_id,
        description: values.description
      });
      
      if (response.data.success) {
        openNotification('success', 'Setting updated successfully');
        setEditModalVisible(false);
        fetchSettings(); // Refresh settings
      } else {
        openNotification('error', 'Failed to update setting');
      }
    } catch (error) {
      console.error('Save failed:', error);
      openNotification('error', 'Failed to update setting');
    }
  };

  // Value renderer based on setting type
  const renderValue = (value, record) => {
    // For boolean values (like feature flags)
    if (value === 'true' || value === 'false') {
      return value === 'true' ? 'Enabled' : 'Disabled';
    }
    // For date aggregation
    if (record.key === 'date_aggregation') {
      const mappings = {
        'day': 'Day',
        'week': 'Week',
        'month': 'Month',
        'quarter': 'Quarter',
        'year': 'Year'
      };
      return mappings[value] || value;
    }
    return value;
  };

  const columns = [
    {
      title: 'Setting Name',
      dataIndex: 'key',
      key: 'key',
      sorter: (a, b) => a.key.localeCompare(b.key),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: renderValue
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Scope',
      dataIndex: 'scope',
      key: 'scope',
      filters: [
        { text: 'Global', value: 'global' },
        { text: 'Organization', value: 'organization' },
        { text: 'User', value: 'user' },
        { text: 'Feature', value: 'feature' },
      ],
      onFilter: (value, record) => record.scope === value,
      render: scope => scope.charAt(0).toUpperCase() + scope.slice(1)
    },
  ];

  return (
    <Layout className="min-h-screen">
      <Content className="container-custom py-8">
        <div className="flex justify-center items-center mb-8 mt-8">
          <h1 className="font-semibold text-[32px] text-[#1D2E54]">System Settings</h1>
        </div>
        
        <Spin spinning={loading}>
          <Table 
            dataSource={settings} 
            columns={columns} 
            rowKey="key"
            pagination={{ pageSize: 10 }}
          />
        </Spin>
      </Content>
    </Layout>
  );
};

export default SettingsPage; 