// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { heatmaps } from "../heatmap/heatmapConfig";

export const HomeHeatmapTab = () => {
  // Get the user's organization ID from Redux store
  const user = useSelector((store: RootState) => store.user.user);
  
  // Filter heatmaps based on organization ID
  const filteredHeatmaps = heatmaps.filter(heatmap => 
    heatmap.org_id === null || heatmap.org_id === user.org_id
  );

  return (
    <div className="max-w-[864px] w-full accent-block">
      <h2 className="font-semibold border-b border-b-[#E6E6E7] py-6 text-[#1D2E54] text-[20px] text-center">
        My DriverMaps
      </h2>
      <div className="p-6 flex flex-col items-center justify-center gap-4">
        {filteredHeatmaps.map(heatmap => (
          <Link key={heatmap.id} to={`/drivermap/${heatmap.id}`}>
            <button
              className="text-center text-[14px] w-[300px] duration-300 ease-in-out rounded-[10px] border border-[#CACBCB] font-semibold hover:bg-[#F3F2FF] hover:border-[#AAABAC] px-4 py-[10px] text-[#2C2E30]"
            >
              {heatmap.title}
            </button>
          </Link>
        ))}
        
        {filteredHeatmaps.length === 0 && (
          <div className="text-center text-[#828385] py-4">
            No heatmaps available for your organization.
          </div>
        )}
      </div>
    </div>
  );
};